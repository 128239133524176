<template>
  <div class="TeamsPage">
    <el-main v-loading="loading">
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <span class="page-title">
            Teams
          </span>
          <el-card>
            <div
              slot="header"
              class="clearfix"
            >
              <a
                v-if="organization.userStatus === 'OWNER'"
                class="el-button el-button--primary is-plain"
                @click="goToNewTeam"
              >
                <i class="el-icon-plus" />
                New Team
              </a>
            </div>
            <el-table
              :data="teams.all()"
              :default-sort="{ prop: 'name' }"
              stripe
            >
              <el-table-column
                prop="name"
                label="Name"
                sortable
              />
              <el-table-column
                v-if="$route.params.segment === 'personal'"
                prop="ownerName"
                label="Owner(Team lead)"
              />
              <el-table-column label="Members">
                <template slot-scope="scope">
                  <div v-if="!scope.row.users.length ">
                    No members
                  </div>
                  <div v-if="scope.row.users.length === 1">
                    {{ scope.row.users[0].name }}
                  </div>
                  <div v-if="scope.row.users.length === 2">
                    {{ scope.row.users[0].name }} and
                    {{ scope.row.users[1].name }}
                  </div>
                  <div v-if="scope.row.users.length === 3">
                    {{ scope.row.users[0].name }},
                    {{ scope.row.users[1].name }} and
                    {{ scope.row.users[2].name }}
                  </div>
                  <div v-if="scope.row.users.length > 3">
                    {{ scope.row.users[0].name }},
                    {{ scope.row.users[1].name }},
                    {{ scope.row.users[2].name }} and
                    <el-button
                      type="text"
                      @click="showMembers(scope.row.users)"
                    >
                      others...
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label=""
                width="80"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.ownerUuid === user.uuid">
                    <el-button
                      type="plain"
                      size="mini"
                      @click="goToTeam(scope.row.uuid)"
                    >
                      Edit
                    </el-button>
                  </div>
                  <div v-if="organization.userStatus === 'OWNER'">
                    <el-button
                      type="plain"
                      size="mini"
                      @click="goToTeam(scope.row.uuid)"
                    >
                      Edit
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <template slot="empty">
                <div
                  v-if="teamRepository.loading"
                  class="well text-center"
                >
                  Loading teams, please wait...
                </div>
                <div
                  v-else
                  class="well text-center"
                >
                  No teams yet.
                </div>
              </template>
            </el-table>
            <!--members modal-->
            <el-dialog
              :visible.sync="membersTableVisible"
              title="Members"
            >
              <el-table
                :data="members"
                :default-sort="{name: 'name'}"
              >
                <el-table-column
                  property="name"
                  label="Name"
                  sortable
                />
                <el-table-column
                  property="email"
                  label="Email"
                />
              </el-table>
              <span
                slot="footer"
                class="dialog-footer"
              >
                <el-button
                  @click="membersTableVisible = false"
                >
                  Close
                </el-button>
              </span>
            </el-dialog>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { TeamRepository } from '@timeragent/core';
import notification from '../../mixins/notification';
import loading from '../../mixins/loading';
import accessRights from '../../mixins/accessRights';

export default {
  mixins: [notification, loading, accessRights],

  data: () => ({
    membersTableVisible: false,
    members: [],
    teamRepository: new TeamRepository(),
  }),

  computed: {
    ...mapGetters([
      'user',
      'organization',
    ]),
    teams() {
      return this.teamRepository.dataset;
    },
  },

  created() {
    this.teamRepository.many({
      filters: {
        organizationUuid: this.$route.params.organizationUuid,
      },
    });

    this.teamRepository.subscribeToMore({
      userUuid: this.user.uuid,
    });
  },

  methods: {
    goToNewTeam() {
      this.startLoading();

      this.$router.push({
        name: 'newTeam',
        params: {
          organizationUuid: this.organizationUuid,
        },
      });
    },
    goToTeam(teamUuid) {
      this.startLoading();
      this.$router.push({ name: 'editTeam', params: { teamUuid } });
    },
    showMembers(members) {
      this.members = members;
      this.membersTableVisible = true;
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

  a.el-button {
    text-decoration: none;
  }

  a.el-button:hover {
    text-decoration: none;
  }

  a.el-button:focus {
    text-decoration: none;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

</style>
