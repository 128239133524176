<template>
  <div class="ProfilePage">
    <el-main v-loading="loading">
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <div class="pull-right">
            <el-button
              type="plain"
              @click="$router.go(-1)"
            >
              Cancel
            </el-button>
            <el-button
              :disabled="formInvalid"
              type="success"
              @click="updateUser"
            >
              Save
            </el-button>
          </div>
          <span class="page-title">
            My Profile
          </span>
          <el-col :span="24">
            <el-card>
              <el-row>
                <el-col
                  :span="16"
                  :offset="4"
                >
                  <el-row>
                    <label>
                      Logo
                    </label>
                    <UploadPicture
                      circle
                      :field-from-state="user"
                      @logoFile="applyLogo"
                    />
                  </el-row>
                  <el-row>
                    <label>
                      First Name
                    </label>
                    <el-input
                      v-model="localUser.firstName"
                      :class="{
                        'has-error': $v.localUser.firstName.$error
                      }"
                      placeholder="Enter your name"
                      @input="$v.localUser.firstName.$touch()"
                    />
                    <div
                      v-if="$v.localUser.firstName.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.localUser.firstName.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                    </div>
                  </el-row>
                  <el-row>
                    <label>
                      Last Name
                    </label>
                    <el-input
                      v-model="localUser.lastName"
                      :class="{
                        'has-error': $v.localUser.lastName.$error
                      }"
                      placeholder="Enter your name"
                      @input="$v.localUser.lastName.$touch()"
                    />
                    <div
                      v-if="$v.localUser.lastName.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.localUser.lastName.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                    </div>
                  </el-row>
                  <el-row>
                    <label>
                      Email
                    </label>
                    <el-input
                      v-model="localUser.email"
                      :class="{
                        'has-error': $v.localUser.email.$error
                      }"
                      placeholder="Enter your email"
                      disabled
                      @input="$v.localUser.email.$touch()"
                      @blur="validateEmail"
                    />
                    <div
                      v-if="$v.localUser.email.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.localUser.email.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                      <span
                        v-if="!$v.localUser.email.isEmail"
                        class="error-message"
                      >
                        Invalid email
                      </span>
                      <span
                        v-if="!$v.localUser.email.isUniqueEmail"
                        class="error-message"
                      >
                        Email is used by another user
                      </span>
                    </div>
                  </el-row>
                  <el-row>
                    <el-checkbox
                      v-model="localUser.consolidateAmounts"
                      :disabled="currencyLoading"
                    >
                      Consolidate amounts to a single currency
                    </el-checkbox>
                  </el-row>

                  <template v-if="localUser.consolidateAmounts">
                    <el-row>
                      <label>
                        Preferred currency
                      </label>
                      <!--suppress HtmlUnknownBooleanAttribute -->
                      <el-select
                        v-model="localUser.currency"
                        :disabled="currencyLoading"
                        :placeholder="currencyLoading ? 'Loading...' : 'Select'"
                        value-key="uuid"
                        autocomplete
                        filterable
                      >
                        <el-option
                          v-for="currency in currencies"
                          :key="currency.uuid"
                          :label="currency.code"
                          :value="currency"
                        />
                      </el-select>
                    </el-row>
                  </template>
                  <hr>
                  <el-row>
                    <label>
                      Current password
                    </label>
                    <br>
                    <el-input
                      v-model="currentPassword"
                      :class="{
                        'has-error' : $v.currentPassword.$error
                          || errors.currentPassword
                      }"
                      type="password"
                      placeholder="Enter old password"
                      @input="$v.currentPassword.$touch()"
                    />
                    <div
                      v-if="$v.currentPassword.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.localUser.currentPassword.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                    </div>
                    <div
                      v-if="errors.currentPassword"
                      class="errors"
                    >
                      <span
                        v-for="message in errors.currentPassword"
                        :key="message"
                        class="error-message"
                      >
                        {{ message }}
                      </span>
                    </div>
                  </el-row>
                  <el-row>
                    <label>New password</label>
                    <br>
                    <!--suppress CommaExpressionJS -->
                    <el-input
                      v-model="localUser.password"
                      :class="{
                        'has-error' : $v.passwords.$error ||
                          $v.localUser.password.$error}"
                      type="password"
                      placeholder="Enter new password"
                      @input="$v.passwords.$touch(),
                              $v.localUser.password.$touch()"
                      @blur="checkForSame"
                    />
                    <div
                      v-if="$v.passwords.$error ||
                        $v.localUser.password.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.passwords.areSame"
                        class="error-message"
                      >
                        Passwords are not the same
                      </span>
                      <span
                        v-if="!$v.localUser.password.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                    </div>
                  </el-row>
                  <el-row>
                    <label>
                      Confirm password
                    </label>
                    <br>
                    <!--suppress CommaExpressionJS -->
                    <el-input
                      v-model="confirmPassword"
                      :class="{
                        'has-error' : $v.passwords.$error ||
                          $v.confirmPassword.$error
                      }"
                      type="password"
                      placeholder="Confirm password"
                      @input="$v.passwords.$touch(),
                              $v.confirmPassword.$touch()"
                      @blur="checkForSame"
                    />
                    <div
                      v-if="$v.passwords.$error ||
                        $v.confirmPassword.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.passwords.areSame"
                        class="error-message"
                      >
                        Passwords are not the same
                      </span>
                      <span
                        v-if="!$v.confirmPassword.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                    </div>
                  </el-row>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';

import { User, S3Uploader } from '@timeragent/core';

import notification from '../../mixins/notification';
import loading from '../../mixins/loading';

export default {
  components: {
    UploadPicture: () => import('../blocks/UploadPicture.vue'),
  },
  mixins: [notification, loading],
  data() {
    return {
      localUser: new User(),
      localS3Uploader: new S3Uploader(),

      confirmPassword: '',
      currentPassword: '',

      isUniqueEmail: true,
      isEmail: true,
      samePasswords: true,
      errors: [],
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'currencies',
      'defaultCurrency',
    ]),
    formInvalid() {
      return this.$v.$invalid;
    },
    currencyLoading() {
      return !this.currencies.length || !this.localUser.currency;
    },
  },
  async created() {
    Object.assign(this.localUser, this.user);

    if (!this.user.currency) {
      this.localUser.currency = this.defaultCurrency;
    }
  },
  methods: {
    ...mapActions([
      'getMe',
      'clearUser',
      'updateUser',
    ]),
    applyLogo(file) {
      if (!file) return;
      if (typeof file === 'object') {
        this.localS3Uploader.file = file.raw;
        const extension = this.localS3Uploader.file.name.split('.').pop();

        this.localUser.logo = `users/${this.localUser.uuid}/logo.${extension}`;
      }
      if (typeof file === 'string') {
        const extension = file.split('logo.').pop().split('?');

        this.localUser.logo = `organizations/${this.localUser.uuid}/logo.${extension}`;
      }
    },
    async updateUser() {
      if (this.$v.$invalid) return;
      try {
        if (this.localS3Uploader.file) {
          await this.localS3Uploader.save(this.localUser.logo, 'putObject');
        } else {
          const extension = this.localUser.logo.split('logo.').pop().split(',')[0];

          await this.localS3Uploader.remove(`organizations/${this.localUser.uuid}/logo.${extension}`);
          this.localUser.logo = '';
        }
        await this.localUser.save();
        this.showSuccess('User saved successfully');
        this.getMe({ updateUser: true });
        this.$router.go(-1);
      } catch (e) {
        this.showError(e.message);
      }
    },
    validateEmail() {
      this.isEmail = !!this.localUser.email.match(/^[0-9a-z-.]+@[0-9a-z-]{2,}\.[a-z]{2,}$/i);
      if (this.localUser.email !== this.user.email) {
        this.$store.dispatch('validateEmail', { email: this.localUser.email })
          .then(response => {
            this.isUniqueEmail = response.data === '';
          });
      } else {
        this.isUniqueEmail = true;
      }
    },
    checkForSame() {
      if (this.confirmPassword === '' || this.localUser.password === '') {
        this.samePasswords = true;

        return;
      }
      if (this.localUser.password !== this.confirmPassword) {
        this.samePasswords = false;

        return;
      }
      this.samePasswords = true;
    },
  },
  validations() {
    if (this.localUser.password !== '' || this.confirmPassword !== '') {
      return {
        localUser: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          email: {
            required,
            isEmail() {
              return this.isEmail;
            },
            isUniqueEmail() {
              return this.isUniqueEmail;
            },
          },
          password: {
            required,
          },
        },
        currentPassword: {
          required,
        },
        confirmPassword: {
          required,
        },
        passwords: {
          areSame() {
            return this.samePasswords;
          },
        },
      };
    }

    return {
      localUser: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          isEmail() {
            return this.isEmail;
          },
          isUniqueEmail() {
            return this.isUniqueEmail;
          },
        },
        password: {},
      },
      currentPassword: {},
      confirmPassword: {},
      passwords: {
        areSame() {
          return true;
        },
      },
    };
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

  .el-row {
    margin-top: 20px;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

  hr {
    margin-top: 30px;
  }
</style>
