<template>
  <div class="ReportsPage">
    <el-main v-loading="loading">
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <span class="page-title">
            Reports
          </span>
          <el-card>
            <DataFilter
              :grouping-options="groupingOptions"
              @apply="applyFilters"
              @export="exportReport"
            />
          </el-card>
          <el-card class="report-container">
            <AppSpinner v-if="reportDataRepository.loading" />
            <ReportData
              v-else
              :entries="reportData.all()"
              :summary="reportDataRepository.summary"
              @refresh="refresh"
            />
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { stringToBlob, ReportDataRepository } from '@timeragent/core';

import notification from '../../mixins/notification';
import loading from '../../mixins/loading';
import accessRights from '../../mixins/accessRights';

export default {
  name: 'ReportsPage',

  components: {
    DataFilter: () => import('../blocks/DataFilter.vue'),
    ReportData: () => import('../blocks/ReportData.vue'),
    AppSpinner: () => import('../blocks/AppSpinner.vue'),
  },

  mixins: [notification, loading, accessRights],

  data() {
    return {
      members: [],
      reportLoading: false,
      currentFilters: {},
      reportDataRepository: new ReportDataRepository(),
      groupingOptions: [
        {
          value: '',
          label: 'None',
          disabled: false,
        },
        {
          value: 'task',
          label: 'Task',
          disabled: false,
        },
        {
          value: 'project',
          label: 'Project',
          disabled: false,
        },
        {
          value: 'team',
          label: 'Team',
          disabled: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'user',
      'organization',
    ]),
    reportData() {
      return this.reportDataRepository.dataset;
    },
  },
  mounted() {
    const dateRange = [
      moment().isoWeekday('Monday').startOf('day').toDate(),
      moment().isoWeekday('Sunday').endOf('day').toDate(),
    ];

    this.applyFilters({
      tasksFilters: {
        dateRange,
      },
    });
  },

  methods: {
    ...mapActions([
      'exportOrganizationReportData',
    ]),
    async applyFilters(filters) {
      this.currentFilters = filters;
      await this.reportDataRepository.fetchData({
        currencyUuid: '7818c7fd-9ad8-48ca-b8bb-5b5d44a9e581',
        filters: {
          organizationUuid: this.organizationUuid,
          ...filters,
        },
      });
    },
    async exportReport(format) {
      const { exportOrganizationReportData } = await this.exportOrganizationReportData({
        organizationUuid: this.organization.uuid,
        filters: this.currentFilters,
        format,
      });
      const blob = stringToBlob(
        exportOrganizationReportData.contents,
        exportOrganizationReportData.mimeType,
      );
      const a = document.createElement('a');

      a.href = URL.createObjectURL(blob);
      a.download = `report.${format}`;
      a.click();
      a.remove();
    },
    refresh() {
      this.applyFilters(this.currentFilters);
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

  a.el-button {
    text-decoration: none;
  }

  a.el-button:hover {
    text-decoration: none;
  }

  a.el-button:focus {
    text-decoration: none;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  .report-container {
    position: relative;
    min-height: 60vh;
  }
</style>
