export const SET_TIMER_ID = 'SET_TIMER_ID';
export const STOP_TIMER = 'STOP_TIMER';
export const STOP_TASK = 'STOP_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const SET_TIME_ENTRY = 'SET_TIME_ENTRY';
export const SET_ACTIVE_TIME_ENTRY = 'SET_ACTIVE_TIME_ENTRY';
export const DELETE_TIME_ENTRY = 'DELETE_TIME_ENTRY';
export const SET_ME = 'SET_ME';
export const SET_EXISTS_EMAIL = 'SET_EXISTS_EMAIL';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_PERSONAL_TEAMS = 'SET_PERSONAL_TEAMS';
export const SET_TEAM_REPOSITORY = 'SET_TEAM_REPOSITORY';
export const SET_PROJECT_REPOSITORY = 'SET_PROJECT_REPOSITORY';
export const SET_TIME_ENTRIES = 'SET_TIME_ENTRIES';
export const SET_CLIENT_REPOSITORY = 'SET_CLIENT_REPOSITORY';
export const SET_INVOICE_REPOSITORY = 'SET_INVOICE_REPOSITORY';
export const SET_ORGANIZATION_MEMBER_REPOSITORY = 'SET_ORGANIZATION_MEMBER_REPOSITORY';
export const SET_ONE_TEAM = 'SET_ONE_TEAM';
export const CLEAR_TEAM = 'CLEAR_TEAM';
export const CLEAR_TEAMS = 'CLEAR_TEAMS';
export const SET_ONE_PROJECT = 'SET_ONE_PROJECT';
export const CLEAR_PROJECT = 'CLEAR_PROJECT';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const SET_OWN_TEAMS = 'SET_OWN_TEAMS';
export const SET_OWN_USERS = 'SET_OWN_USERS';
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const SET_CURRENCIES = 'SET_CURRENCIES';
export const SET_DEFAULT_CURRENCY = 'SET_DEFAULT_CURRENCY';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const UPDATE_USER_ORGANIZATIONS = 'UPDATE_USER_ORGANIZATIONS';
export const SET_PROJECT_FOR_CHART = 'SET_PROJECT_FOR_CHART';
export const SET_DATE_FOR_CHART = 'SET_DATE_FOR_CHART';
export const CLEAR_SELECTED_PROJECTS_FOR_CHART = 'CLEAR_SELECTED_PROJECTS_FOR_CHART';
export const SET_TASK_TAGS = 'SET_TASK_TAGS';
export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_ORGANIZATION_MEMBERS = 'SET_ORGANIZATION_MEMBERS';
export const SET_ORGANIZATION_INVITES = 'SET_ORGANIZATION_INVITES';
export const CLEAR_ORGANIZATION_MEMBERS = 'CLEAR_ORGANIZATION_MEMBERS';
export const DELETE_ORGANIZATION_INVITE = 'DELETE_ORGANIZATION_INVITE';
export const SET_REPORT_DATA_REPOSITORY = 'SET_REPORT_DATA_REPOSITORY';
export const SET_CALENDAR_TASKS = 'SET_CALENDAR_TASKS';
export const REMOVE_LOGO_URL = 'REMOVE_LOGO_URL';
export const SET_CONSOLIDATE_AMOUNTS = 'SET_CONSOLIDATE_AMOUNTS';
export const MODULE_REGISTERED = 'MODULE_REGISTERED';
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';
