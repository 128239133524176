import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/clients/:clientUuid(${uuidRegex})`,
  name: 'editClient',
  component: () => import('../components/pages/EditClientPage.vue'),
  props: true,
  meta: {
    name: 'Edit Client',
  },
};
