import { Organization, ProjectRepository, TimeEntry, TimeEntryRepository } from '@timeragent/core';
import moment from 'moment';
import { MutationTree } from 'vuex';
import { IUIState } from '@/store/state';
import * as types from './mutation-types';

export default {
  [types.SET_ME](state, user) {
    Object.assign(state, { user });
  },
  [types.SET_PROJECT_REPOSITORY](state, projectRepository) {
    Object.assign(state, { projectRepository });
  },
  [types.SET_TIME_ENTRIES](state, timeEntries) {
    Object.assign(state, { timeEntries });
  },
  [types.SET_CLIENT_REPOSITORY](state, clientRepository) {
    Object.assign(state, { clientRepository });
  },
  [types.SET_INVOICE_REPOSITORY](state, invoiceRepository) {
    Object.assign(state, { invoiceRepository });
  },
  [types.SET_REPORT_DATA_REPOSITORY](state, reportDataRepository) {
    Object.assign(state, { reportDataRepository });
  },
  [types.SET_TEAM_REPOSITORY](state, teamRepository) {
    Object.assign(state, { teamRepository });
  },
  [types.SET_ORGANIZATION_MEMBER_REPOSITORY](state, organizationMemberRepository) {
    Object.assign(state, { organizationMemberRepository });
  },

  [types.SET_CALENDAR_TASKS](state, calendarTasks) {
    Object.assign(state, { calendarTasks });
  },
  [types.REMOVE_LOGO_URL](state, stateField) {
    Object.assign(stateField, { logo: '' });
  },
  [types.SET_CURRENT_DATE](state: any, date: Date) {
    Object.assign(state, {
      date,
    });
  },

  // #########################
  //      NOT REFACTORED
  // #########################

  [types.SET_TIMER_ID](state, timerId) {
    Object.assign(state, {
      timerId,
      activeTimeEntry: null,
    });
  },

  [types.STOP_TIMER](state) {
    clearInterval(state.timerId);
    Object.assign(state, {
      timerID: 0,
      spendTime: null,
      timerStarted: false,
    });
  },
  [types.STOP_TASK](state, payload) {
    if (payload.withDelete) {
      Object.assign(state, { oldActiveTask: null });
    } else {
      Object.assign(state, { oldActiveTask: state.activeTask });
    }
    Object.assign(state, { activeTask: null });
  },
  [types.DELETE_TASK](state, { uuid, uuids }) {
    let tasks = (uuids) ? state.tasks : [];

    if (uuids && uuids.length > 0) {
      uuids.forEach((u: string) => {
        tasks = tasks.filter(taskInArray => taskInArray.uuid !== u);
      });
    } else {
      tasks = state.tasks.filter(taskInArray => taskInArray.uuid !== uuid);
    }
    Object.assign(state, {
      tasks,
      activeTask: null,
      oldActiveTask: null,
    });
  },

  [types.SET_TIME_ENTRY](state, timeEntry) {
    // do not set time entry if time entry is active and date not equals today date
    // because user will be redirected to today date with today tasks
    if (moment().toDate() !== state.date && timeEntry.active) {
      return;
    }

    const { tasks } = state;

    const taskIndex = tasks.findIndex(task => task.uuid === timeEntry.taskUuid);

    if (taskIndex === -1) {
      return;
    }

    const task = tasks[taskIndex];
    const timeEntryIndex: number = task.timeEntries?.search(timeEntryInArray => timeEntryInArray.uuid === timeEntry.uuid, true) || -1;

    if (timeEntryIndex > -1) {
      // remove time entry if it has less then 60 seconds, else update
      if (moment().diff(moment(timeEntry.startTime, 'YYYY-MM-DD HH:mm:ss'), 'seconds') < 60) {
        tasks[taskIndex].timeEntries?.splice(timeEntryIndex, 1);
      } else {
        const timeEntryDef = tasks[taskIndex]?.timeEntries?.get(timeEntryIndex) || new TimeEntry();

        Object.assign(timeEntryDef, timeEntry);
      }
    } else {
      tasks[taskIndex].timeEntries?.push(timeEntry);
    }

    Object.assign(state, {
      tasks,
    });
  },
  [types.SET_ACTIVE_TIME_ENTRY](state, activeTimeEntry) {
    Object.assign(state, { activeTimeEntry });
  },
  [types.DELETE_TIME_ENTRY](state, timeEntry) {
    const repository = new TimeEntryRepository(state.timeEntryRepository.dataset
      .filter(e => e.uuid !== timeEntry.uuid));

    Object.assign(state, {
      timeEntryRepository: repository,
    });
  },
  [types.SET_EXISTS_EMAIL](state, user) {
    Object.assign(state, {
      existsEmail: user.email,
    });
  },
  [types.CLEAR_PROJECTS](state, projectRepository = new ProjectRepository()) {
    Object.assign(state, { projectRepository });
  },
  [types.CLEAR_SELECTED_PROJECTS_FOR_CHART](state) {
    Object.assign(state, {
      selectedProjectsForChart: [],
      dateForChart: [
        moment().isoWeekday('Monday').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        moment().isoWeekday('Sunday').endOf('day').format('YYYY-MM-DD H:mm:ss'),
      ],
    });
  },
  [types.CLEAR_ORGANIZATION](state, organization = new Organization()) {
    Object.assign(state, { organization });
  },
  [types.SET_CURRENCIES](state, currencies) {
    Object.assign(state, { currencies });
  },
  [types.SET_DEFAULT_CURRENCY](state, defaultCurrency) {
    Object.assign(state, { defaultCurrency });
  },
  [types.SET_ORGANIZATION_MEMBERS](state, organizationMembers) {
    Object.assign(state, {
      organizationMembers,
    });
  },
  [types.CLEAR_ORGANIZATION_MEMBERS](state, organizationMembers = []) {
    Object.assign(state, {
      organizationMembers,
    });
  },
  [types.SET_ORGANIZATION_INVITES](state, organizationInvites) {
    Object.assign(state, {
      organizationInvites,
    });
  },
  [types.SET_ORGANIZATION](state, organization) {
    Object.assign(state, {
      organization,
    });
  },
  [types.DELETE_ORGANIZATION_INVITE](state, invite) {
    const organizationInvites = state.organizationInvites.filter(i => i.uuid !== invite.uuid);

    Object.assign(state, {
      organizationInvites,
    });
  },
  [types.START_LOADING](state) {
    Object.assign(state, {
      loading: true,
    });
  },
  [types.STOP_LOADING](state) {
    Object.assign(state, {
      loading: false,
    });
    document.body.classList.add('loaded');
  },
  [types.UPDATE_USER_ORGANIZATIONS](state, createOrganization) {
    state.user.organizations.push(createOrganization);
  },
  [types.SET_PROJECT_FOR_CHART](state, selectedProjectsForChart) {
    Object.assign(state, {
      selectedProjectsForChart,
    });
  },
  [types.SET_DATE_FOR_CHART](state, dateForChart) {
    Object.assign(state, {
      dateForChart,
    });
  },
  [types.SET_CONSOLIDATE_AMOUNTS](state, consolidateAmounts) {
    Object.assign(state, {
      consolidateAmounts,
    });
  },
  [types.SET_REPORT_DATA](state, reportData) {
    Object.assign(state, {
      reportData,
    });
  },
  [types.MODULE_REGISTERED](state, moduleName: string) {
    state.registeredModules = [...state.registeredModules, moduleName];
  },
} as MutationTree<IUIState>;
