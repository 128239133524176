<template>
  <div>
    <el-main>
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <span class="page-title">
            {{ invoiceTitle }}
          </span>
          <el-card v-if="!showClientModal">
            <div
              class="period-date"
            >
              <span class="period-title">
                Period for:
              </span>
              <span>
                {{ invoice.filters.tasksFilters.dateRange.length ? periodDateTitle(invoice.filters.tasksFilters.dateRange) : '' }}
              </span>
            </div>
          </el-card>
          <el-card class="invoice-container">
            <AppSpinner v-if="invoiceLoading" />
            <div class="actions-row">
              <div>
                <el-button
                  v-if="$route.name === 'invoice'"
                  :disabled="invoice.loading"
                  size="mini"
                  plain
                  @click.prevent="cancel"
                >
                  Go back
                </el-button>
              </div>
              <div>
                <div
                  v-if="$route.name === 'invoice'"
                  class="actions-bar"
                >
                  <el-button
                    type="plain"
                    size="mini"
                    @click="editInvoice"
                  >
                    Edit
                  </el-button>
                  <el-button
                    v-if="!showClientModal && isInvoiceDraft"
                    :disabled="invoice.loading"
                    type="success"
                    size="mini"
                    title="Click to save"
                    @click.prevent="sendInvoice"
                  >
                    <i
                      v-if="invoice.loading"
                      class="el-icon-loading"
                    />
                    Send
                  </el-button>
                  <el-button
                    v-if="!showClientModal && isInvoiceSent"
                    :disabled="invoice.loading"
                    type="success"
                    size="mini"
                    title="Click to save"
                    @click.prevent="sendInvoice"
                  >
                    <i
                      v-if="invoice.loading"
                      class="el-icon-loading"
                    />
                    Resend
                  </el-button>
                </div>
                <div
                  v-if="$route.name === 'editInvoice' || $route.name === 'newInvoice'"
                  class="actions-bar"
                >
                  <el-button
                    v-if="!showClientModal"
                    :disabled="invoice.loading"
                    size="mini"
                    plain
                    @click.prevent="cancel"
                  >
                    Cancel
                  </el-button>
                  <el-button
                    v-if="!showClientModal"
                    :disabled="!isInvoiceChanged && $route.name !== 'newInvoice'"
                    type="success"
                    size="mini"
                    title="Click to save"
                    @click.prevent="save"
                  >
                    <i
                      v-if="invoice.loading"
                      class="el-icon-loading"
                    />
                    Save
                  </el-button>
                </div>
              </div>
            </div>
            <InvoiceData
              v-if="!showClientModal && invoice.filters.groupBy"
              :invoice="invoice"
              :invoice-data-repository="invoiceDataRepository"
              :is-apply="isApply"
              @apply="setIsApply"
            />
          </el-card>
          <div v-if="$route.name === 'editInvoice'">
            <el-button
              v-if="invoice.exists()"
              type="text"
              class="delete_button"
              @click="showConfirmModal = true"
            >
              Delete invoice
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-main>

    <ModalClientPicker
      v-if="showClientModal && $route.name === 'newInvoice'"
      @pick="prepareInvoiceHeader"
      @close="closeClientModal"
    />

    <!-- Confirm delete invoice modal form -->

    <el-dialog
      v-if="invoice.exists()"
      :visible.sync="showConfirmModal"
      title="Delete invoice"
      width="30%"
    >
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click.prevent="showConfirmModal = false"
        >
          Cancel
        </el-button>
        <el-button
          type="danger"
          @click.prevent="deleteInvoice"
        >
          Delete
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { InvoiceRepository, InvoiceDataRepository, Invoice } from '@timeragent/core';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { showError, showSuccess } from '../../../../notification';
import notification from '../../../../mixins/notification';
import loading from '../../../../mixins/loading';

const BillingFrequency = {
  NONE: 'NONE',
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  BI_WEEKLY: 'BI_WEEKLY',
};

export default {
  name: 'InvoicePage',

  components: {
    AppSpinner: () => import('../../../blocks/AppSpinner.vue'),
    InvoiceData: () => import('./InvoiceData.vue'),
    ModalClientPicker: () => import('./ModalClientPicker.vue'),
  },

  mixins: [notification, loading],

  data: () => ({
    invoiceLoading: false,
    showClientModal: false,
    showConfirmModal: false,
    entries: [],
    isApply: false,
    copiedInvoice: {},

    invoice: new Invoice(),
    invoiceRepository: new InvoiceRepository(),
    invoiceDataRepository: new InvoiceDataRepository(),
  }),

  computed: {
    ...mapGetters([
      'organization',
      'user',
    ]),
    invoiceTitle() {
      if (!this.invoice.exists()) {
        return 'New Invoice';
      }
      if (this.$route.name === 'invoice') {
        return 'Invoice';
      }
      if (this.$route.name === 'editInvoice') {
        return 'Edit invoice';
      }

      return '';
    },
    isInvoiceDraft() {
      return this.invoice.status === 'DRAFT';
    },
    isInvoiceSent() {
      return this.invoice.status === 'SENT';
    },
    isInvoiceChanged() {
      return (JSON.stringify(this.invoice) !== JSON.stringify(this.copiedInvoice));
    },
  },

  async created() {
    if (this.$route.name === 'invoice' || this.$route.name === 'editInvoice') {
      this.invoice = await this.invoiceRepository.one(this.$route.params.invoiceUuid);
      const { entries } = this.invoice.data;

      this.entries = JSON.parse(JSON.stringify(entries));
      if (this.entries.length) {
        this.entries.push(this.invoice.data.summary);
      }
      this.invoiceDataRepository.dataset = this.invoiceDataRepository.fromArray(this.entries);
      this.invoiceDataRepository.loading = false;
    }
    if (this.$route.name === 'editInvoice') {
      this.copiedInvoice = JSON.parse(JSON.stringify(this.invoice));
    }
    if (this.$route.name === 'newInvoice') {
      this.showClientModal = true;
      this.invoice.organization = this.organization;
      this.invoice.user = this.user;
      this.invoice.filters.groupBy = 'task';
    }
  },

  methods: {
    periodDateTitle(date) {
      return `${moment(date[0]).format('D MMMM')}
      -${moment(date[1]).format('D MMMM')}`;
    },
    periodDate() {
      if (this.invoice.client.billingFrequency.frequency === BillingFrequency.WEEKLY) {
        return [
          moment().startOf('week').toDate(),
          moment().endOf('week').toDate(),
        ];
      }
      if (this.invoice.client.billingFrequency.frequency === BillingFrequency.BI_WEEKLY) {
        return [
          moment().subtract(1, 'week').startOf('week').toDate(),
          moment().endOf('week').toDate(),
        ];
      }

      return [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ];
    },
    async prepareInvoiceHeader(client, invoiceDate) {
      this.invoice.date = invoiceDate;
      this.invoice.client = client;
      this.invoice.invoicePrefix = client.invoicePrefix;
      this.invoice.invoiceNumber = await this.invoiceRepository.getInvoiceNumber({
        organizationUuid: this.organization.uuid,
        clientUuid: client.uuid,
        userUuid: this.user.uuid,
      });
      this.invoice.filters.clientsFilters.uuids.push(client.uuid);
      this.invoice.filters.tasksFilters.dateRange.push(...this.periodDate());
      this.showClientModal = false;
    },
    async save() {
      try {
        this.invoice.status = 'DRAFT';
        await this.invoice.save();
        this.showSuccess(this.invoice.exists() ? 'Invoice updated successfully' : 'Invoice created successfully');
        this.$router.go(-1);
      } catch (e) {
        this.showError();
      }
    },
    async cancel() {
      if (this.$route.name === 'editInvoice') {
        this.invoice = await this.invoiceRepository.one(this.$route.params.invoiceUuid);
        const { entries } = this.invoice.data;

        this.entries = JSON.parse(JSON.stringify(entries));
        if (this.entries.length) {
          this.entries.push(this.invoice.data.summary);
        }
        this.invoiceDataRepository.dataset = this.invoiceDataRepository.fromArray(this.entries);
      }
      this.isApply = false;
      this.$router.go(-1);
    },
    async sendInvoice() {
      try {
        await this.invoice.sendInvoice();
        this.showSuccess('Invoice sent successfully');
        this.$router.push({ name: 'invoicesPage', params: { organizationUuid: this.organization.uuid } });
      } catch (e) {
        console.error(e);
        showError(e.message);
      }
    },
    editInvoice() {
      this.$router.push({ name: 'editInvoice', params: { invoiceUuid: this.invoice.uuid } });
    },
    closeClientModal() {
      this.showClientModal = false;
    },
    setIsApply(val) {
      this.isApply = val;
    },
    async deleteInvoice() {
      try {
        await this.invoice.delete();

        showSuccess('Invoice deleted successfully');
        this.$router.push({ name: 'invoicesPage', params: { organizationUuid: this.organization.uuid } });
      } catch (e) {
        showError(e.message);
      }
      this.showConfirmModal = false;
    },
  },
};
</script>

<style
  scoped
  lang="stylus"
  rel="stylesheet/css"
>
  .invoice-container {
    min-height: 500px;
  }
  a.el-button {
    text-decoration: none;
  }

  a.el-button:hover {
    text-decoration: none;
  }

  a.el-button:focus {
    text-decoration: none;
  }

  .period-title {
    color: #3D8ECA;
  }

  .actions-row {
    display: flex;
    justify-content: space-between;
  }

  .actions-bar {
    display: flex;
    justify-content: flex-end;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  .period-date {
    color: #6A717C;
    font-size: 16px;
  }

</style>
