<template>
  <el-main>
    <el-row>
      <el-col
        :span="22"
        :offset="1"
      >
        <span class="page-title">
          Register
        </span>
        <el-col :span="24">
          <el-card>
            <el-row>
              <el-col
                :span="21"
                :offset="1"
              >
                <el-form
                  @keyup.enter.native="registerUser"
                >
                  <el-row>
                    <label>
                      First Name
                    </label>
                    <el-input
                      v-model="user.firstName"
                      :class="{
                        'has-error': $v.user.firstName.$error
                      }"
                      placeholder="Enter your first name"
                      @blur="$v.user.firstName.$touch()"
                    />
                    <div
                      v-if="$v.user.firstName.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.user.firstName.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                      <span
                        v-if="!$v.user.firstName.minLength"
                        class="error-message"
                      >
                        Minimum length 3 characters
                      </span>
                    </div>
                  </el-row>
                  <!--                  Last Name-->
                  <el-row>
                    <label>
                      Last Name
                    </label>
                    <el-input
                      v-model="user.lastName"
                      :class="{
                        'has-error': $v.user.lastName.$error
                      }"
                      placeholder="Enter your last name"
                      @blur="$v.user.lastName.$touch()"
                    />
                    <div
                      v-if="$v.user.lastName.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.user.lastName.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                      <span
                        v-if="!$v.user.lastName.minLength"
                        class="error-message"
                      >
                        Minimum length 3 characters
                      </span>
                    </div>
                  </el-row>
                  <!--                  Middle Name-->
                  <el-row>
                    <label>
                      Middle Name
                    </label>
                    <el-input
                      v-model="user.middleName"
                      placeholder="Enter your middle name"
                    />
                  </el-row>
                  <!--                  Email-->
                  <el-row>
                    <label>Email</label>
                    <el-input
                      v-model="user.email"
                      :class="{
                        'has-error': $v.user.email.$error
                      }"
                      placeholder="Enter your email"
                      @blur="$v.user.email.$touch()"
                    />
                    <div
                      v-if="$v.user.email.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.user.email.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                      <span
                        v-if="!$v.user.email.isEmail"
                        class="error-message"
                      >
                        Invalid email
                      </span>
                    </div>
                  </el-row>
                  <!--                  Password-->
                  <el-row>
                    <label>Password</label>
                    <br>
                    <div class="password">
                      <el-input
                        v-model="user.password"
                        :class="{
                          'has-error': $v.user.password.$error
                        }"
                        :type="passwordInputType"
                        placeholder="Enter new password"
                        @blur="$v.user.password.$touch()"
                      />
                      <i
                        class="far eye-password"
                        :class="{
                          'fa-eye': passwordToggle,
                          'fa-eye-slash': !passwordToggle,
                        }"
                        @click.prevent="passwordToggle = !passwordToggle"
                      />
                      <div
                        v-if="$v.user.password.$error"
                        class="errors"
                      >
                        <span
                          v-if="!$v.user.password.required"
                          class="error-message"
                        >
                          Field is required
                        </span>
                        <span
                          v-if="!$v.user.password.minLength"
                          class="error-message"
                        >
                          Minimum length 6 characters
                        </span>
                      </div>
                    </div>
                  </el-row>
                  <!--                  Confirm password-->
                  <el-row>
                    <label>
                      Confirm password
                    </label>
                    <br>
                    <div class="password">
                      <el-input
                        v-model="user.confirmPassword"
                        :class="{
                          'has-error': $v.user.confirmPassword.$error
                        }"
                        :type="passwordInputType"
                        placeholder="Confirm password"
                        @blur="$v.user.confirmPassword.$touch()"
                      />
                      <i
                        class="far eye-password"
                        :class="{
                          'fa-eye': passwordToggle,
                          'fa-eye-slash': !passwordToggle,
                        }"
                        @click.prevent="passwordToggle = !passwordToggle"
                      />
                      <div
                        v-if="$v.user.confirmPassword.$error"
                        class="errors"
                      >
                        <span
                          v-if="!$v.user.confirmPassword.required"
                          class="error-message"
                        >
                          Field is required
                        </span>
                        <span
                          v-if="!$v.user.confirmPassword.sameAs"
                          class="error-message"
                        >
                          Passwords are not the same
                        </span>
                      </div>
                    </div>
                  </el-row>
                  <div class="action-buttons">
                    <el-button
                      :disabled="formInvalid"
                      :loading="$v.user.$model.loading"
                      type="success"
                      @click="registerUser"
                    >
                      Register
                    </el-button>
                  </div>
                </el-form>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { User } from '@timeragent/core';

import loading from '../../mixins/loading';
import notification from '../../mixins/notification';

export default {
  mixins: [notification, loading],
  data() {
    return {
      user: new User(),

      isEmail: true,
      passwordToggle: true,
    };
  },
  computed: {
    ...mapGetters([
      'existsEmail',
    ]),
    formInvalid() {
      return this.$v.$invalid;
    },
    passwordInputType() {
      return this.passwordToggle ? 'password' : 'text';
    },
  },
  methods: {
    async registerUser() {
      if (this.formInvalid) {
        this.$v.$touch();

        return;
      }
      try {
        const message = await this.user.signUp();

        this.showSuccess(message);

        this.$router.push({
          name: 'login',
          params: {
            email: this.user.email,
          },
        });
      } catch (e) {
        this.showError(e.message);
      }
    },
  },
  validations() {
    return {
      user: {
        firstName: {
          required,
          minLength: minLength(3),
        },
        lastName: {
          required,
          minLength: minLength(3),
        },
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(6),
        },
        confirmPassword: {
          required,
          sameAs: sameAs('password'),
        },
      },
    };
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

  .el-row {
    margin-top: 20px;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

  .action-buttons {
    margin-top: 20px;
  }

  .password {
    position: relative
  }

  .eye-password {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #656565;
    cursor : pointer;
  }

</style>
