<template>
  <div class="EditProjectPage edit-project-page">
    <div class="header">
      <div class="page-title">
        {{ project.exists() ? 'Edit Project' : 'New Project' }}
      </div>
      <!--personal-->
      <div>
        <el-button
          :disabled="saving"
          type="plain"
          @click.prevent="cancel"
        >
          Cancel
        </el-button>
        <el-button
          :disabled="formInvalid || project.loading"
          type="success"
          title="Add Project"
          @click.prevent="project.exists() ? updateProject() : createProject()"
        >
          <i
            v-if="project.loading"
            class="el-icon-loading"
          />
          Save
        </el-button>
      </div>
    </div>
    <el-col :span="24">
      <el-card class="wrapper">
        <div class="form">
          <div class="form__item">
            <label>
              Name
            </label>
            <el-input
              v-model="project.name"
              :class="{ 'has-error': $v.project.name.$error }"
              placeholder="Enter project name"
              @input="$v.project.name.$touch()"
            />
            <div
              v-if="$v.project.name.$error"
              class="errors"
            >
              <span
                v-if="!$v.project.name.required"
                class="error-message"
              >
                Field is required
              </span>
            </div>
          </div>
          <div class="form__item">
            <label>
              Client
            </label>
            <div>
              <el-select
                v-model="project.client"
                :disabled="clientRepository.loading"
                :placeholder="clientRepository.loading ? 'Loading...' : 'Select'"
                value-key="uuid"
                @input="$v.project.client.uuid.$touch()"
              >
                <el-option
                  :value="null"
                  label="No client"
                />
                <el-option
                  v-for="client in clients.all()"
                  :key="client.uuid"
                  :label="client.name"
                  :value="client"
                />
              </el-select>
            </div>
            <div
              v-if="$v.project.client.$error"
              class="errors"
            >
              <span
                v-if="!$v.project.client.uuid.required"
                class="error-message"
              >
                Field is required
              </span>
            </div>
          </div>
          <div class="form__item">
            <label>
              Currency
            </label>
            <div>
              <el-select
                v-model="project.currency"
                :disabled="currencyRepository.loading"
                :placeholder="currencyRepository.loading ? 'Loading...' : 'Select'"
                value-key="uuid"
                autocomplete
                filterable
                @input="$v.project.currency.uuid.$touch()"
              >
                <el-option
                  :value="null"
                  label="No currency"
                />
                <el-option
                  v-for="currency in currencies.all()"
                  :key="currency.uuid"
                  :label="currency.code"
                  :value="currency"
                />
              </el-select>
            </div>
            <div
              v-if="$v.project.currency.$error"
              class="errors"
            >
              <span
                v-if="!$v.project.currency.uuid.required"
                class="error-message"
              >
                Field is required
              </span>
            </div>
          </div>

          <el-tabs v-model="activeTabName">
            <el-tab-pane
              label="Teams"
              name="teams"
            >
              <el-row
                type="flex"
                justify="space-around"
              >
                <el-autocomplete
                  v-model="queryTeamsString"
                  :fetch-suggestions="searchTeams"
                  :trigger-on-focus="false"
                  placeholder="Search teams"
                  value-key="name"
                  class="members-search-input"
                  @select="addTeam"
                />
              </el-row>
              <el-row
                type="flex"
                justify="space-around"
              >
                <el-table
                  :data="project.teams"
                  :default-sort="{ prop: 'name' }"
                  stripe
                  empty-text="No Teams"
                >
                  <el-table-column
                    prop="name"
                    label="Name"
                    sortable
                  >
                    <template slot-scope="scope">
                      <el-popover
                        ref="members"
                        placement="right"
                        width="500"
                        trigger="click"
                        @hide="toggleShowTeamUsers(scope.row)"
                      >
                        <el-table :data="teamUsers">
                          <el-table-column
                            width="200"
                            property="name"
                            label="Name"
                          />
                          <el-table-column
                            label="Rate"
                            width="150"
                          >
                            <template slot-scope="scope2">
                              {{ project.currency.symbol }} {{ scope2.row.options.payableRate }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="Daily Limit"
                            width="150"
                          >
                            <template
                              v-if="scope2.row.options.timeLimit"
                              slot-scope="scope2"
                            >
                              {{ scope2.row.options.timeLimit }} h
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-popover>
                      <el-button
                        v-popover:members
                        type="text"
                        @click="toggleShowTeamUsers(scope.row)"
                      >
                        {{ scope.row.name }}
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column width="80">
                    <template slot-scope="scope">
                      <el-button
                        type="danger"
                        plain
                        size="mini"
                        icon="el-icon-delete"
                        @click="removeTeam(scope.row.uuid)"
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
            </el-tab-pane>
          </el-tabs>

          <div v-if="project.exists()">
            <div>
              <el-button
                type="text"
                class="delete_button"
                @click="showConfirmModal = true"
              >
                Delete Project
              </el-button>
            </div>

            <!-- Confirm delete project modal form -->
            <el-dialog
              :visible.sync="showConfirmModal"
              title="Delete team"
              width="30%"
            >
              <p>
                It will not be undone.
                Please enter project name to continue:
                <br>
                ({{ project.name }})
              </p>
              <el-input
                v-model="projectName"
                placeholder="Enter project name"
              />
              <span
                slot="footer"
                class="dialog-footer"
              >
                <el-button
                  @click.prevent="showConfirmModal = false"
                >
                  Cancel
                </el-button>
                <el-button
                  :disabled="!confirmDeleteProject"
                  type="danger"
                  @click.prevent="deleteProject"
                >
                  Delete
                </el-button>
              </span>
            </el-dialog>
          </div>
        </div>
      </el-card>
    </el-col>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { ProjectRepository, ClientRepository, CurrencyRepository, TeamRepository, Project } from '@timeragent/core';

import notification from '../../mixins/notification';

import loading from '../../mixins/loading';

export default {
  mixins: [notification, loading],
  props: {
    projectUuid: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    project: new Project(),
    queryTeams: [],
    queryTeamsSent: false,
    queryTeamsString: '',

    showConfirmModal: false,
    activeTabName: 'teams',
    projectName: '',

    teamUsers: [],
    showedTeamUuid: null,

    saving: false,

    // new
    clientRepository: new ClientRepository(),
    currencyRepository: new CurrencyRepository(),
    projectRepository: new ProjectRepository(),
    teamRepository: new TeamRepository(),
  }),
  computed: {
    // Checking is form valid
    formInvalid() {
      return this.$v.$invalid;
    },
    // Getters
    ...mapGetters([
      'user',
      'organization',
      'defaultCurrency',
    ]),
    clients() {
      return this.clientRepository.dataset;
    },
    currencies() {
      return this.currencyRepository.dataset;
    },
    teams() {
      return this.teamRepository.dataset;
    },
    // Checking is verifying name equal project name to confirm deleting
    confirmDeleteProject() {
      return this.projectName === this.project.name;
    },
    currencyLoading() {
      return !this.currencies.length || !this.project.currency;
    },
  },
  async created() {
    if (this.$route.name === 'editProject') {
      this.project = await this.projectRepository.one(this.$route.params.projectUuid);
    }

    this.clientRepository.many({
      filters: {
        organizationUuid: this.organizationUuid,
      },
    });

    this.currencyRepository.many();
  },

  methods: {
    searchTeams(queryString, cb) {
      if (queryString.length > 2 && !this.queryTeamsSent) {
        try {
          this.teamRepository.many({
            filters: {
              organizationUuid: this.organizationUuid,
              teamsFilters: {
                name: queryString,
              },
            },
          });

          this.queryTeamsSent = true;
        } catch (e) {
          console.error(e);
          this.showError(e.message);
        }
      } else {
        this.queryTeamsSent = false;
      }

      const results = queryString ? this.teams.all().filter(this.createTeamsFilter(queryString)) : [];
      // call callback function to return suggestions

      cb(results);
    },
    createTeamsFilter(queryString) {
      return item => (
        item.name.toLowerCase().search(queryString.toLowerCase()) >= 0
                && !this.project.teams.find(t => t.name === item.name)
      );
    },
    addTeam(team) {
      this.project.teams.push(team);
      this.queryTeamsString = '';
    },
    removeTeam(teamUuid) {
      this.project.teams = this.project.teams
        .filter(projectTeam => projectTeam.uuid !== teamUuid);
    },
    // Add project
    async createProject() {
      if (this.$v.$invalid) return;

      this.saving = true;

      this.project.ownerType = 'organization';
      this.project.ownerUuid = this.organization.uuid;

      try {
        await this.project.create();
        this.showSuccess('Project saved successful');
        this.$router.go(-1);
      } catch (e) {
        this.showError(e);
        this.saving = false;
      }
    },
    // Update project
    async updateProject() {
      if (this.$v.$invalid) return;

      this.saving = true; // Start spinner

      this.project.ownerType = 'organization';
      this.project.ownerUuid = this.organization.uuid;

      try {
        await this.project.update();
        this.showSuccess('Project saved successful');
        this.$router.go(-1);
      } catch (e) {
        this.showError(e);
        this.saving = false;
      }
    },
    // Delete project
    deleteProject() {
      if (!this.confirmDeleteProject) return;

      this.showConfirmModal = false;

      try {
        this.project.delete();
        this.showSuccess('Project deleted successful');
        this.$router.go(-1);
      } catch (e) {
        this.showError();
      }
    },
    toggleShowTeamUsers(team) {
      this.teamUsers = team.users;
      this.showedTeamUuid = team.uuid;
    },
  },
  validations: {
    project: {
      name: {
        required,
      },
      client: {
        uuid: {
          required,
        },
      },
      currency: {
        uuid: {
          required,
        },
      },
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>
  .edit-project-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .wrapper {
    max-width: 1723px;
    width: 100%;
    & >>> {
      .el-card__body {
        display: flex;
        justify-content: center;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1723px;
  }

  .form {
    width: 100%;
    max-width: 1120px;

    &__item {
      margin-top: 20px;
    }
  }

  .el-tabs {
    margin-top: 30px;
  }

  .teams {
    margin-top: 40px;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

  .members-search-input {
    width: 100%;
  }

</style>
