import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/projects/new`,
  name: 'newProject',
  component: () => import('../components/pages/EditProjectPage.vue'),
  meta: {
    name: 'New Project',
  },
};
