<template>
  <el-main
    v-loading="loading"
    class="RecoveryPage"
  >
    <el-row class="RecoveryPage__Widget">
      <el-col
        :span="12"
        :offset="6"
      >
        <span
          v-if="!showMessage"
          class="page-title"
        >
          Password recovery
        </span>
        <span
          v-else
          class="page-title"
        >
          A confirmation email has been sent to your email
        </span>
        <el-col
          v-if="!showMessage"
          :span="24"
        >
          <el-card>
            <el-row>
              <el-col
                :span="22"
                :offset="1"
              >
                <el-form
                  :disabled="model.loading"
                  @keyup.enter.native="sendEmail"
                >
                  <el-row>
                    <label>Email</label>
                    <el-input
                      v-model="user.email"
                      :class="{
                        'has-error': $v.user.email.$error
                      }"
                      placeholder="Enter your email"
                      @blur="$v.user.email.$touch()"
                    />
                    <div
                      v-if="$v.user.email.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.user.email.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                      <span
                        v-if="!$v.user.email.isEmail"
                        class="error-message"
                      >
                        Invalid email
                      </span>
                    </div>
                  </el-row>
                  <div class="action-buttons">
                    <el-button
                      :disabled="!$v.user.email.required"
                      :loading="$v.user.$model.loading"
                      type="primary"
                      size="small"
                      @click.prevent="sendEmail"
                    >
                      Send
                    </el-button>
                  </div>
                </el-form>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { loginMixin, User } from '@timeragent/core';
import { email, required } from 'vuelidate/lib/validators';
import loading from '../../mixins/loading';
import notification from '../../mixins/notification';

export default {
  mixins: [loginMixin, loading, notification],
  data() {
    return {
      user: new User(),
      showMessage: false,
    };
  },

  mounted() {
    if (this.$route.params.email) {
      this.model.email = this.$route.params.email;
    }
  },

  methods: {
    async sendEmail() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      try {
        const message = await this.user.recoveryPassword();

        this.showSuccess(message);

        this.showMessage = true;
      } catch (e) {
        this.showError('This email was not found');
        this.errors = e;
      }
    },
  },
  validations() {
    return {
      user: {
        email: {
          required,
          email,
        },
      },
    };
  },
};
</script>

<style lang="stylus"
       rel="stylesheet/css"
       scoped
>

.RecoveryPage {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__Widget {
    min-width 1200px;
    min-height 600px;
  }
}

.el-row {
  margin-top: 20px;
}

.page-title {
  padding: 0;
  font-size: 28px;
  line-height: 33px;
  display: block;
  margin: 0 0 25px;
  height: 40px;
  word-break: break-all;
}

body {
  background-color: #efefef;
}

.action-buttons {
  margin-top: 20px;
}

</style>
