<template>
  <div class="ClientsPage">
    <el-main v-loading="loading">
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <span
            v-if="$route.name === 'invoicesPage'"
            class="page-title"
          >
            Invoices
          </span>
          <el-card>
            <div
              slot="header"
              class="clearfix"
            >
              <router-link
                :to="{
                  name: 'newInvoice',
                  params: {
                    invoices,
                    segment: 'organization',
                    organizationUuid: $route.params.organizationUuid
                  }
                }"
                class="el-button el-button--primary is-plain"
              >
                <i class="el-icon-plus" />
                New Invoice
              </router-link>
            </div>
            <OopTable
              ref="oopTable"
              :repository="invoiceRepository"
              :columns="columns"
              no-auto-fetch
              @select="goToInvoice"
            >
              <template #level0-cell="scope">
                <div v-if="scope.columnIndex === 0">
                  {{ `${scope.row.invoicePrefix}-${scope.row.invoiceNumber}` }}
                </div>
                <div v-if="scope.columnIndex === 1">
                  {{ getFormatStatus(scope.row.status) }}
                </div>
                <div v-if="scope.columnIndex === 2">
                  {{ scope.row.client.name }}
                </div>
                <div v-if="scope.columnIndex === 3">
                  {{ getFormatDateRange(scope.row) }}
                </div>
              </template>
            </OopTable>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { InvoiceRepository } from '@timeragent/core';
import notification from '../../../../mixins/notification';
import loading from '../../../../mixins/loading';

export default {
  name: 'InvoicesPage',

  mixins: [notification, loading],

  data: () => ({
    invoiceRepository: new InvoiceRepository(),

    columns: {
      invoice: {
        title: 'Invoice',
        width: '3fr',
      },
      status: {
        title: 'Status',
        width: '2fr',
      },
      client: {
        title: 'Client',
        width: '2fr',
      },
      date: {
        title: 'Date range',
        width: '2fr',
      },
    },
  }),

  computed: {
    ...mapGetters([
      'organization',
    ]),
    invoices() {
      return this.invoiceRepository.dataset;
    },
  },

  created() {
    this.invoiceRepository.many({
      filters: {
        organizationUuid: this.$route.params.organizationUuid,
      },
    });
  },

  methods: {
    goToInvoice({ row }) {
      this.$router.push({ name: 'invoice', params: { invoiceUuid: row.uuid } });
    },
    generateUrl(url) {
      return `/${this.$route.params.segment}/${this.$route.params.organizationUuid}/${url}`;
    },
    getFormatStatus(status) {
      switch (status) {
      case 'DRAFT':
        return 'Draft';
      case 'SENT': {
        return 'Sent';
      }
      case 'PAID': {
        return 'Paid';
      }
      case 'ARCHIVED': {
        return 'Archived';
      }
      default:
        return '';
      }
    },
    getFormatDateRange(row) {
      return `${moment(row.filters.tasksFilters.dateRange[0]).format('DD/MM/YYYY')}
      - ${moment(row.filters.tasksFilters.dateRange[1]).format('DD/MM/YYYY')}`;
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

  a.el-button {
    text-decoration: none;
  }

  a.el-button:hover {
    text-decoration: none;
  }

  a.el-button:focus {
    text-decoration: none;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

</style>
