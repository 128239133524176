<template>
  <div class="ClientsPage">
    <el-main v-loading="loading">
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <span class="page-title">
            Clients
          </span>
          <el-card>
            <div
              slot="header"
              class="clearfix"
            >
              <router-link
                :to="{
                  name: 'newClient',
                  params: {
                    segment: 'organization',
                    organizationUuid: $route.params.organizationUuid
                  }
                }"
                class="el-button el-button--primary is-plain"
              >
                <i class="el-icon-plus" />
                New Client
              </router-link>
            </div>
            <el-table
              :data="clients.all()"
              :default-sort="{prop: 'name'}"
              stripe
            >
              <el-table-column
                prop="name"
                label="Name"
                sortable
              />
              <el-table-column label="Contact Name">
                <template slot-scope="scope">
                  <div>
                    {{ `${scope.row.contact.firstName} ${scope.row.contact.lastName}` }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label=""
                width="80"
              >
                <template slot-scope="scope">
                  <div v-if="organization.userStatus === 'OWNER'">
                    <el-button
                      type="plain"
                      size="mini"
                      @click="goToClient(scope.row.uuid)"
                    >
                      Edit
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <template slot="empty">
                <div
                  v-if="clientRepository.loading"
                  class="well text-center"
                >
                  Loading clients, please wait...
                </div>
                <div
                  v-else
                  class="well text-center"
                >
                  No clients yet.
                </div>
              </template>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ClientRepository } from '@timeragent/core';
import notification from '../../mixins/notification';
import loading from '../../mixins/loading';

export default {
  name: 'ClientPage',

  mixins: [notification, loading],

  data: () => ({
    clientRepository: new ClientRepository(),
  }),

  computed: {
    ...mapGetters([
      'organization',
    ]),
    clients() {
      return this.clientRepository.dataset;
    },
  },

  created() {
    this.clientRepository.many({
      filters: {
        organizationUuid: this.$route.params.organizationUuid,
      },
    });
  },

  methods: {
    goToClient(clientUuid) {
      this.$router.push({ name: 'editClient', params: { clientUuid } });
    },
    generateUrl(url) {
      return `/${this.$route.params.segment}/${this.$route.params.organizationUuid}/${url}`;
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

  a.el-button {
    text-decoration: none;
  }

  a.el-button:hover {
    text-decoration: none;
  }

  a.el-button:focus {
    text-decoration: none;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

</style>
