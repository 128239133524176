<template>
  <div class="EditOrganizationPage">
    <el-main>
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <div class="pull-right">
            <el-button
              plain
              @click.prevent="cancel"
            >
              Cancel
            </el-button>
            <el-button
              :disabled="formInvalid"
              type="success"
              title="Save"
              @click.prevent="localOrganization.exists() ? updateOrganization() : createOrganization()"
            >
              Save
            </el-button>
          </div>
          <span class="page-title">
            {{ localOrganization.exists() ? 'Edit Organization' : 'New Organization' }}
          </span>
          <el-col :span="24">
            <el-card>
              <el-row>
                <el-col
                  :span="16"
                  :offset="4"
                >
                  <el-row>
                    <label>
                      Logo
                    </label>
                    <UploadPicture
                      :field-from-state="organization"
                      @logoFile="applyLogo"
                    />
                  </el-row>
                  <el-row>
                    <label>
                      Name
                    </label>
                    <el-input
                      v-model="localOrganization.name"
                      :class="{
                        'has-error': $v.localOrganization.name.$error
                      }"
                      placeholder="Enter organization name"
                      @input="$v.localOrganization.name.$touch()"
                    />
                    <div
                      v-if="$v.localOrganization.name.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.localOrganization.name.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                    </div>
                  </el-row>
                  <el-row>
                    <label>
                      Email
                    </label>
                    <el-input
                      v-model="localOrganization.email"
                      :class="{
                        'has-error': $v.localOrganization.email.$error
                      }"
                      placeholder="Enter organization email"
                      @input="$v.localOrganization.email.$touch()"
                      @blur="validateEmail"
                    />
                    <div
                      v-if="$v.localOrganization.email.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.localOrganization.email.isEmail"
                        class="error-message"
                      >
                        Invalid Email
                      </span>
                      <span
                        v-if="!$v.localOrganization.email.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                    </div>
                  </el-row>
                  <el-row>
                    <label>
                      Phone
                    </label>
                    <el-input
                      v-model="localOrganization.phone"
                      placeholder="Enter organization phone"
                    />
                  </el-row>
                  <el-row>
                    <label>
                      Website
                    </label>
                    <el-input
                      v-model="localOrganization.website"
                      placeholder="Enter organization website"
                    />
                  </el-row>
                  <el-row>
                    <label>
                      Address
                    </label>
                    <el-input
                      v-model="localOrganization.address"
                      :rows="5"
                      type="textarea"
                      placeholder="Enter organization address"
                    />
                  </el-row>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

import { Organization, S3Uploader } from '@timeragent/core';

import notification from '../../mixins/notification';

export default {
  components: {
    UploadPicture: () => import('../blocks/UploadPicture.vue'),
  },

  mixins: [notification],

  beforeRouteLeave(to, from, next) {
    if (to.name === 'newOrganization') {
      this.localOrganization = new Organization();
    } else {
      Object.assign(this.localOrganization, this.organization);
    }
    next();
  },

  data() {
    return {
      isEmail: true,
      saving: false,

      localOrganization: new Organization(),
      localS3Uploader: new S3Uploader(),
    };
  },

  computed: {
    formInvalid() {
      return this.$v.$invalid;
    },
    ...mapGetters([
      'organization',
      'user',
    ]),
  },

  watch: {
    organization(org) {
      if (this.$route.name === 'newOrganization') return;
      Object.assign(this.localOrganization, org);
    },
  },

  async created() {
    if (this.$route.name === 'editOrganization') {
      await this.getMe({ updateOrg: true });
      Object.assign(this.localOrganization, this.organization);
    }
  },
  methods: {
    ...mapActions([
      'clearOrganization',
      'getMe',
    ]),
    applyLogo(file) {
      if (!file) return;
      if (typeof file === 'object') {
        this.localS3Uploader.file = file.raw;
        const extension = this.localS3Uploader.file.name.split('.').pop();

        this.localOrganization.logo = `organizations/${this.localOrganization.uuid}/logo.${extension}`;
      }
      if (typeof file === 'string') {
        const extension = file.split('logo.').pop().split('?');

        this.localOrganization.logo = `organizations/${this.localOrganization.uuid}/logo.${extension}`;
      }
    },
    async createOrganization() {
      if (this.formInvalid) return;
      try {
        await this.localOrganization.create();
        if (this.localOrganization.logo) {
          await this.localS3Uploader.save(this.localOrganization.logo, 'putObject');
        }
        this.showSuccess('Organization created successfully');
        this.$router.push(`/${this.localOrganization.uuid}/tasks`);
        this.clearOrganization();
      } catch (e) {
        this.showError();
        console.warn(e);
        this.saving = false;
      }
      this.getMe(true);
    },
    async updateOrganization() {
      if (this.formInvalid) return;
      try {
        if (this.localS3Uploader.file) {
          await this.localS3Uploader.save(this.localOrganization.logo, 'putObject');
        } else {
          const extension = this.localOrganization.logo.split('logo.').pop().split(',')[0];

          await this.localS3Uploader.remove(`organizations/${this.localOrganization.uuid}/logo.${extension}`);
          this.localOrganization.logo = '';
        }
        await this.localOrganization.update();
        this.showSuccess('Organization saved successfully');
        this.$router.push(`/${this.localOrganization.uuid}/tasks`);
      } catch (e) {
        this.showError(e.message);
      }
      this.getMe(true);
    },
    validateEmail() {
      this.isEmail = !!(this.localOrganization.email.match(/^[0-9a-z-.]+@[0-9a-z-]{2,}\.[a-z]{2,}$/i)
        || this.localOrganization.email === '');
    },
    cancel() {
      this.$router.push({ name: 'tasks', params: { orgUuid: this.organization.uuid } });
    },
  },
  validations: {
    localOrganization: {
      name: {
        required,
      },
      email: {
        required,
        isEmail() {
          return this.isEmail;
        },
      },
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

  .el-row {
    margin-top: 20px;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

</style>
