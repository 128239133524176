import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/invoices/:invoiceUuid(${uuidRegex})`,
  name: 'invoice',
  component: () => import('../components/pages/accounting/invoices/InvoicePage.vue'),
  props: true,
  meta: {
    name: 'Invoice',
  },
};
