<template>
  <div class="InviteMembersPage invite-members-page">
    <div class="header">
      <span
        class="page-title"
      >
        Manage employees
      </span>
    </div>
    <el-card class="wrapper">
      <div class="form">
        <div class="invite-section">
          <div>
            <el-select
              v-model="invite.emails"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="Type member email"
            />
          </div>
          <div>
            <el-button
              type="success"
              title="Click to send invite"
              @click.prevent="sendInvite"
            >
              <i
                v-if="invite.loading"
                class="el-icon-loading"
              />
              Invite
            </el-button>
          </div>
        </div>
        <div>
          <el-table
            v-if="invites.count()"
            class="invites-table"
            :data="invites.all()"
            :default-sort="{ prop: 'email' }"
            stripe
            empty-text="No members"
          >
            <el-table-column
              prop="email"
              label="Email"
              sortable
            />
            <el-table-column
              label="Status"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status === 'PENDING'">
                  <i class="far fa-clock" /> Pending...
                </span>
              </template>
            </el-table-column>
            <el-table-column width="120">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status === 'PENDING'"
                  type="info"
                  plain
                  size="mini"
                  icon="el-icon-refresh-left"
                  title="Re-send notification"
                  @click="resendInvite(scope.row)"
                />
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  title="Remove employee"
                  @click="prepareDeletingInvite(scope.row)"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="members">
          <el-table
            v-if="members.length"
            :data="members"
            :default-sort="{ prop: 'name' }"
            stripe
            empty-text="No members"
          >
            <el-table-column
              prop="name"
              label="Name"
              sortable
            />
            <el-table-column
              label="Status"
              sortable
            >
              <template slot-scope="scope">
                <span
                  v-if="scope.row.options.status === 'OWNER'"
                >
                  <i class="el-icon-success gray-text" />
                  Owner
                </span>
                <span v-else>
                  Member
                </span>
              </template>
            </el-table-column>
            <el-table-column width="80">
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.options.status === 'OWNER'"
                  type="danger"
                  plain
                  size="mini"
                  icon="el-icon-delete"
                  @click="prepareDeletingMember(scope.row)"
                />
              </template>
            </el-table-column>
          </el-table>
          <el-dialog
            :visible.sync="showConfirmModal"
            title="Delete member"
            width="30%"
          >
            <p>
              It will not be undone. Delete {{ memberToBeDeleted.name }}?
            </p>
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button
                @click.prevent="showConfirmModal = false"
              >
                Cancel
              </el-button>
              <el-button
                type="danger"
                @click.prevent="deleteMember(memberToBeDeleted.uuid)"
              >
                Delete
              </el-button>
            </span>
          </el-dialog>
          <!-- Delete invite confirm modal-->
          <el-dialog
            :visible.sync="confirmDeleteInvite"
            title="Delete invitation"
            width="30%"
          >
            <p>
              It will not be undone. Delete invitation of {{ inviteToBeDeleted.email }}?
            </p>
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button
                @click.prevent="confirmDeleteInvite = false"
              >
                Cancel
              </el-button>
              <el-button
                type="danger"
                @click.prevent="deleteInvite(inviteToBeDeleted.uuid)"
              >
                <i
                  v-if="inviteToBeDeleted.loading"
                  class="el-icon-loading"
                />
                Delete
              </el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
import { OrganizationInvite, OrganizationInviteRepository } from '@timeragent/core';
import notification from '../../mixins/notification';
import { showSuccess } from '../../notification';

export default {
  mixins: [notification],
  data() {
    return {
      invite: new OrganizationInvite(),
      organizationInviteRepository: new OrganizationInviteRepository(),

      members: [],
      saving: false,
      showConfirmModal: false,
      memberToBeDeleted: {},
      inviteToBeDeleted: {},
      confirmDeleteInvite: false,
    };
  },
  computed: {
    ...mapGetters([
      'organization',
    ]),
    invites() {
      return this.organizationInviteRepository.dataset;
    },
  },

  created() {
    this.members = cloneDeep(this.organization.members) || [];
    this.organizationInviteRepository.many({
      organizationUuid: this.organizationUuid,
    });
  },

  methods: {
    async sendInvite() {
      if (!this.invite.emails.length || this.invite.loading) return;

      try {
        const invites = await this.invite.sendInvites();

        this.organizationInviteRepository.dataset.push(...invites);

        // Clear invite section
        this.invite = new OrganizationInvite();

        this.showSuccess('Invitations sent');
      } catch (e) {
        this.showError(e.message);
      }
    },
    async deleteInvite() {
      try {
        await this.inviteToBeDeleted.delete();

        // Remove invite from list
        this.organizationInviteRepository.dataset = this.organizationInviteRepository.dataset
          .filter(i => i.uuid !== this.inviteToBeDeleted.uuid);

        this.confirmDeleteInvite = false;
        this.inviteToBeDeleted = {};
      } catch (e) {
        console.error(e);
        this.showError(e.message);
      }
    },
    async resendInvite(invite) {
      try {
        await invite.resend();
        showSuccess('The notification has been re-sent.');
      } catch (e) {
        console.error(e);
        this.showError(e.message);
      }
    },
    prepareDeletingInvite(invite) {
      this.inviteToBeDeleted = invite;
      this.confirmDeleteInvite = true;
    },
    prepareDeletingMember(member) {
      this.memberToBeDeleted = member;
      this.showConfirmModal = true;
    },
    async deleteMember(userUuid) {
      try {
        await this.organization.deleteMember({
          userUuid,
        });

        this.members = this.members
          .filter(m => m.uuid !== userUuid);

        this.memberToBeDeleted = {};
        this.showConfirmModal = false;
      } catch (e) {
        this.showError(e.message);
      }
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>
  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

  .members-emails {
    width: 100%;
  }

  .el-select {
    width: 100%;
  }

  .invite-section {
    display: grid;
    grid-template-columns: auto 75px;
    column-gap: 20px;
  }

  .invites-table {
    margin-bottom: 20px;
  }

  .invite-members-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .wrapper {
    max-width: 1723px;
    width: 100%;

    & >>> {
      .el-card__body {
        display: flex;
        justify-content: center;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1723px;
  }

  .form {
    width: 100%;
    max-width: 1120px;
  }

  .members {
    margin-top: 20px;
  }

</style>

<style
  lang="stylus"
  rel="stylesheet/css"
>

  .members-search-input {
    width: 100%;
  }

</style>
