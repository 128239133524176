import moment from 'moment';
import {
  User,
  Organization,
  ProjectRepository,
  TeamRepository,
  ClientRepository,
  InvoiceRepository,
  OrganizationMemberRepository,
  ReportDataRepository,
  TaskRepository,
  Task,
  Tag,
  TimeEntry,
  Team,
  Project,
  Currency,
  OrganizationInvite, TimeEntryRepository,
} from '@timeragent/core';

export interface IUIState {
  registeredModules: string[];
  user: User;
  date: Date;
  projectRepository: ProjectRepository;
  timeEntryRepository: TimeEntryRepository;
  teamRepository: TeamRepository;
  clientRepository: ClientRepository;
  invoiceRepository: InvoiceRepository;
  organizationMemberRepository: OrganizationMemberRepository;
  reportDataRepository: ReportDataRepository;
  selectedProjectsForChart: Project[];
  dateForChart: [string, string];
  organization: Organization;
  calendarTasks: TaskRepository;
  timerStarted: boolean;
  tasksExists: boolean;
  timerId: number;
  spendTime: number | null;
  total: number | null;
  tasks: Task[];
  activeTask: Task | null;
  oldActiveTask: Task | null;
  existsEmail: string;
  taskTags: Tag[];
  activeTimeEntry: TimeEntry | null;
  timeEntry: TimeEntry | null;
  timeEntries: TimeEntry[];
  organizationTeams: Team[];
  project: Project | null;
  ownTeams: Team[];
  ownUsers: User[];
  allUsers: User[];
  organizations: Organization[];
  profileStatus: unknown;
  currencies: Currency[];
  defaultCurrency: Currency | null;
  organizationMembers: User[];
  organizationInvites: OrganizationInvite[];
  loading: false;
  team: Team | null;
}

export default {
  registeredModules: [],
  user: new User(),
  date: moment().toDate(),
  projectRepository: new ProjectRepository(),
  timeEntryRepository: new TimeEntryRepository(),
  teamRepository: new TeamRepository(),
  clientRepository: new ClientRepository(),
  invoiceRepository: new InvoiceRepository(),
  organizationMemberRepository: new OrganizationMemberRepository(),
  reportDataRepository: new ReportDataRepository(),
  selectedProjectsForChart: [],
  dateForChart: [
    moment().isoWeekday('Monday').startOf('day').format('YYYY-MM-DD H:mm:ss'),
    moment().isoWeekday('Sunday').endOf('day').format('YYYY-MM-DD H:mm:ss'),
  ],
  organization: new Organization(),
  calendarTasks: new TaskRepository(),

  // #########################
  //      NOT REFACTORED
  // #########################
  tasks: [],
  timerStarted: false,
  tasksExists: true,
  timerId: 0,
  spendTime: null,
  total: null,
  activeTask: null,
  oldActiveTask: null,
  existsEmail: '',
  taskTags: [],
  activeTimeEntry: null,
  organizationTeams: [],
  team: null,
  project: null,
  timeEntry: null,
  timeEntries: [],
  ownTeams: [],
  ownUsers: [],
  allUsers: [],
  organizations: [],
  profileStatus: '',
  currencies: [],
  defaultCurrency: null,
  organizationMembers: [],
  organizationInvites: [],
  loading: false,
} as IUIState;
