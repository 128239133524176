import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/clients/new`,
  name: 'newClient',
  component: () => import('../components/pages/EditClientPage.vue'),
  props: true,
  meta: {
    name: 'New Client',
  },
};
