import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/teams/new`,
  name: 'newTeam',
  component: () => import('../components/pages/EditTeamPage.vue'),
  meta: {
    name: 'New Team',
  },
};
