// import store from '@/store';

export default {
  path: '/',
  name: 'home',
  // redirect: to => ({
  //   name: 'tasks',
  //   params: {
  //     organizationUuid: store.getters.user.organizations[0].uuid,
  //   },
  // }),
  meta: {
    name: 'Home',
  },
};
