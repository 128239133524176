import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/profile`,
  name: 'Profile',
  component: () => import('../components/pages/ProfilePage.vue'),
  meta: {
    name: 'Profile',
  },
};
