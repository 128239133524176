<template>
  <el-main
    v-loading="loading"
    class="TasksPage tasks-page"
  >
    <div class="tasks-header">
      <DataFilter
        no-date-label
        no-filter-label1
        no-tag-filter
        no-description-filter
        no-team-filter
        no-grouping-options
        @apply="applyFilters"
      />
      <TaskTotal :total="taskRepository.totalTime" />
    </div>
    <TaskList
      class="tasks"
      :tasks="taskRepository.dataset"
      :loading="taskRepository.loading"
      @create="addCreatedTask"
      @delete="deleteTask"
      @delete-tasks="deleteTasks"
    />
  </el-main>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { TaskRepository } from '@timeragent/core';
import loading from '../../mixins/loading';
import notification from '../../mixins/notification';
import { showError } from '../../notification';

export default {
  components: {
    DataFilter: () => import('../blocks/DataFilter.vue'),
    TaskTotal: () => import('./tasks-page/TaskTotal.vue'),
    TaskList: () => import('./tasks-page/TaskList.vue'),
  },

  mixins: [loading, notification],

  data: () => ({
    dateRange: [
      moment().startOf('day').toDate(),
      moment().endOf('day').toDate(),
    ],
    taskRepository: new TaskRepository(),
  }),

  computed: {
    ...mapGetters([
      'organization',
      'user',
    ]),
  },

  created() {
    try {
      this.getTasks({
        tasksFilters: {
          dateRange: this.dateRange,
        },
      });
    } catch (e) {
      this.showError('Unable to load tasks.');
    }
  },

  methods: {
    ...mapActions([
      'setCurrentDate',
    ]),
    addCreatedTask(task) {
      this.taskRepository.dataset.push(task);
    },
    deleteTask(task) {
      this.taskRepository.dataset = this.taskRepository.dataset.filter(t => t.uuid !== task.uuid);
    },

    async deleteTasks({ uuids }) {
      try {
        await this.taskRepository.delete(uuids);
      } catch (e) {
        showError(e.message);
        console.error(e);
      }
    },

    async applyFilters(filters) {
      await this.getTasks(filters);
    },
    getTasks(filters) {
      this.setCurrentDate(filters.tasksFilters.dateRange[0]);

      this.taskRepository.many({
        filters: {
          organizationUuid: this.organizationUuid,
          ...filters,
        },
      });
    },
  },
};
</script>

<style
  lang="stylus"
  scoped
>
  .tasks-page
    display flex
    flex-direction column
    align-items: center;

  .tasks-header
    width 100%
    display grid
    grid-template-columns auto max-content
    margin-bottom 10px
    max-width 1723px

  .tasks
    max-width 1723px
    width 100%
</style>
