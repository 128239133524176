<template>
  <div>
    <el-main>
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <el-card>
            <div>
              <ChartFilter />
            </div>
          </el-card>
          <el-card class="card-list">
            <div class="row-top">
              <div class="area-chart">
                <ColumnChart
                  v-if="selectedProjectsForChart.length"
                />
              </div>
              <div>
                <div class="productivity-score">
                  <ProductivityScoreChart
                    v-if="selectedProjectsForChart.length"
                  />
                </div>
              </div>
            </div>
            <div>
              <TotalTimeMoney
                class="total-time-money"
              />
            </div>
            <div class="table-chart">
              <TableChart
                v-if="selectedProjectsForChart.length"
              />
            </div>
            <div
              v-if="selectedProjectsForChart.length < 1"
              class="selectYourProject"
            >
              Please select your project ☝
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DashboardPage',

  components: {
    TableChart: () => import('./TableChart.vue'),
    ChartFilter: () => import('./ChartFilter.vue'),
    ColumnChart: () => import('./ColumnChart.vue'),
    ProductivityScoreChart: () => import('./ProductivityScoreChart.vue'),
    TotalTimeMoney: () => import('./TotalTimeMoney.vue'),
  },

  beforeRouteUpdate(to, from, next) {
    this.clearProjects();
    this.setOrganization(to.params.organizationUuid);
    this.clearSelectedProjectsForChart();
    this.getProjects({
      withTasks: true,
      organizationUuid: to.params.organizationUuid,
    }, this.selectedProjectsForChart)
      .then(() => {
        this.setProjectForChart(this.filteredProjectsForCharts);
        next();
      });
  },

  computed: {
    ...mapGetters([
      'selectedProjectsForChart',
      'user',
      'filteredProjectsForCharts',
    ]),
  },

  methods: {
    ...mapActions([
      'getProjects',
      'clearSelectedProjectsForChart',
      'setProjectForChart',
      'clearProjects',
      'setOrganization',
      'setUser',
    ]),
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>
 body {
   background-color: #efefef;
 }
 .row-top {
   display: grid;
   grid-template: auto / 80% 20%;
  }
  .selectYourProject {
    font-size: 22px;
    text-align: right;
    padding-bottom: 300px;
    padding-top: 300px;
    color: #6A717C;
  }
  .table-chart {
    padding-bottom: 20px;
  }
  .total-time-money {
    margin-bottom: 10px;
    text-align: right;
  }
</style>
