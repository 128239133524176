import { Route } from 'vue-router';
import { uuidRegex } from '@/helpers/constants';
import store from '@/store';

export default {
  path: `/:organizationUuid(${uuidRegex})/dashboard`,
  name: 'dashboardPage',
  component: () => import('../components/pages/dashboard/DashboardPage.vue'),
  meta: {
    name: 'Dashboard',
    actions: [
      ['clearProjects'],

      (to: Route) => ((store.getters.selectedProjectsForChart.length
        && store.getters.selectedProjectsForChart[0].ownerUuid
        !== to.params.organizationUuid
      ) ? 'clearSelectedProjectsForChart' : ''),

      (to: Route) => ['setOrganization', to.params.organizationUuid],

      (to: Route) => ['getProjects', {
        withTasks: true,
        organizationUuid: to.params.organizationUuid,
      }],
    ],
  },
};
