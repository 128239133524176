<template>
  <div class="MembersPage">
    <el-main v-loading="loading">
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <span class="page-title">
            Members
          </span>
          <el-card>
            <div
              v-if="organization.userStatus === 'OWNER'"
              slot="header"
              class="clearfix"
            >
              <router-link
                to="members/invite"
                class="el-button el-button--primary is-plain"
              >
                <i class="el-icon-plus" />
                Manage Members
              </router-link>
            </div>
            <el-table
              :data="organization.members"
              :default-sort="{ prop: 'name' }"
              stripe
              empty-text="No members"
            >
              <el-table-column
                prop="name"
                label="Name"
                sortable
              />
              <el-table-column
                label="Status"
                sortable
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.options.status === 'OWNER'"
                  >
                    <i class="el-icon-success gray-text" />
                    Owner
                  </span>
                  <span v-else>
                    Member
                  </span>
                </template>
              </el-table-column>
              <template slot="empty">
                <div
                  v-if="false"
                  class="well text-center"
                >
                  Loading organization members, please wait...
                </div>
                <div
                  v-else
                  class="well text-center"
                >
                  No members yet.
                </div>
              </template>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UserRepository } from '@timeragent/core';

import notification from '../../mixins/notification';
import loading from '../../mixins/loading';
import accessRights from '../../mixins/accessRights';

export default {
  mixins: [notification, loading, accessRights],

  data() {
    return {
      userRepository: new UserRepository(),
    };
  },

  computed: {
    ...mapGetters([
      'user',
      'organization',
    ]),
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

    a.el-button {
        text-decoration: none;
    }

    a.el-button:hover {
        text-decoration: none;
    }
    a.el-button:focus {
        text-decoration: none;
    }

    .page-title {
        padding: 0;
        font-size: 28px;
        line-height: 33px;
        display: block;
        margin: 0 0 25px;
        height: 40px;
        word-break: break-all;
    }

    body {
        background-color: #efefef;
    }

</style>
