<template>
  <el-main
    v-loading="loading"
    class="LoginPage"
  >
    <el-row class="LoginPage__Widget">
      <el-col
        :span="12"
        :offset="6"
      >
        <span class="page-title">
          Please sign in
        </span>
        <el-col :span="24">
          <el-card>
            <el-row>
              <el-col
                :span="22"
                :offset="1"
              >
                <el-form
                  :disabled="model.loading"
                  @keyup.enter.native="loginUser"
                >
                  <!--                  Email-->
                  <el-row>
                    <label>
                      Email
                    </label>
                    <el-input
                      v-model="model.email"
                      :class="{
                        'has-error': $v.model.email.$error
                      }"
                      placeholder="Enter your email"
                      @blur="$v.model.email.$touch()"
                      @keyup.enter.native="loginUser"
                    />
                    <div
                      v-if="$v.model.email.$error"
                      class="errors"
                    >
                      <span
                        v-if="!$v.model.email.required"
                        class="error-message"
                      >
                        Field is required
                      </span>
                      <span
                        v-if="!$v.model.email.isEmail"
                        class="error-message"
                      >
                        Invalid email
                      </span>
                    </div>
                  </el-row>
                  <!--                  Password-->
                  <el-row>
                    <label>
                      Password
                    </label>
                    <br>
                    <div class="password">
                      <el-input
                        v-model="model.password"
                        :class="{
                          'has-error' : $v.model.password.$error
                        }"
                        :type="passwordInputType"
                        placeholder="Enter password"
                        @blur="$v.model.password.$touch()"
                        @keyup.enter.native="loginUser"
                      />
                      <i
                        class="far eye-password"
                        :class="{
                          'fa-eye': passwordToggle,
                          'fa-eye-slash': !passwordToggle,
                        }"
                        @click.prevent="passwordToggle = !passwordToggle"
                      />
                      <div
                        v-if="$v.model.password.$error"
                        class="errors"
                      >
                        <span
                          v-if="!$v.model.password.required"
                          class="error-message"
                        >
                          Field is required
                        </span>
                      </div>
                    </div>
                  </el-row>
                  <span
                    class="recovery-btn"
                    @click="forgotPassword"
                  >
                    Forgot password?
                  </span>
                  <div class="action-buttons">
                    <el-button
                      :disabled="model.loading"
                      :loading="model.loading"
                      type="success"
                      size="small"
                      @click.prevent="loginUser"
                    >
                      Login
                    </el-button>
                  </div>
                </el-form>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { loginMixin } from '@timeragent/core';
import { mapGetters, mapActions } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';
import loading from '../../mixins/loading';
import notification from '../../mixins/notification';

export default {
  mixins: [loginMixin, loading, notification],

  data() {
    return {
      passwordToggle: true,
    };
  },

  computed: {
    ...mapGetters([
      'user',
    ]),
    passwordInputType() {
      return this.passwordToggle ? 'password' : 'text';
    },
  },

  mounted() {
    if (this.$route.params.email) {
      this.model.email = this.$route.params.email;
    }
  },

  methods: {
    ...mapActions([
      'getMe',
    ]),
    async loginUser() {
      try {
        await this.login();
      } catch (e) {
        this.showError(e.message);

        return;
      }
      await this.getMe();

      // Applying redirect to target auth needed route after login
      if (this.$route.params.redirect) {
        console.log(`Applying redirect to ${this.$route.params.redirect}`);
        this.$router.push({
          path: this.$route.params.redirect,
        });

        return;
      }

      this.$router.push({ name: 'tasks', params: { organizationUuid: `${this.user.organizations[0].uuid}` } });
    },

    forgotPassword() {
      this.$router.push({ name: 'recovery', params: { email: this.model.email } });
    },
  },
  validations() {
    return {
      model: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    };
  },
};
</script>

<style lang="stylus"
       rel="stylesheet/css"
       scoped
>

  .LoginPage {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__Widget {
      min-width 1200px;
      min-height 600px;
    }
  }

  .el-row {
    margin-top: 20px;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

  .recovery-btn {
    margin-top : 50px;
    color  : #409EFF;
    cursor : pointer;
  }

  .recovery-btn:hover {
    color : #006cff;
  }

  .action-buttons {
    margin-top: 20px;
  }

  .password {
    position: relative
  }

  .eye-password {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #656565;
    cursor : pointer;
  }

</style>
