const workspace = {
  computed: {
    // @ts-ignore
    organizationUuid() {
      // @ts-ignore
      return this.$route?.params?.organizationUuid;
    },
  },
};

export default workspace;
