import { uuidRegex } from '@/helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/poker-sessions/new`,
  name: 'newPokerSession',
  component: () => import('../components/pages/poker-planning-page/PokerSession.vue'),
  props: true,
  meta: {
    name: 'New Session',
  },
};
