import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import { OrganizationInvite } from '@timeragent/core';
import { showError, showSuccess } from '@/notification';
import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/invites/:inviteUuid(${uuidRegex})`,

  async beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
    if (to.query.action !== 'accept') return;

    const invite = new OrganizationInvite();

    invite.uuid = to.params.inviteUuid;

    try {
      const message = await invite.accept() as string;

      showSuccess(message as string);

      next({
        name: 'members',
        params: {
          organizationUuid: to.params.organizationUuid,
        },
      });
    } catch (e) {
      // TODO: Implement graphql errors handler
      if (e.graphQLErrors && e.graphQLErrors.length) {
        showError(e.graphQLErrors[0].message);
      } else {
        showError(e.message);
      }
      next('/404');
    }
  },
};
