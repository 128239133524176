var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ClientsPage"},[_c('el-main',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('el-row',[_c('el-col',{attrs:{"span":22,"offset":1}},[(_vm.$route.name === 'invoicesPage')?_c('span',{staticClass:"page-title"},[_vm._v(" Invoices ")]):_vm._e(),_c('el-card',[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('router-link',{staticClass:"el-button el-button--primary is-plain",attrs:{"to":{
                name: 'newInvoice',
                params: {
                  invoices: _vm.invoices,
                  segment: 'organization',
                  organizationUuid: _vm.$route.params.organizationUuid
                }
              }}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" New Invoice ")])],1),_c('OopTable',{ref:"oopTable",attrs:{"repository":_vm.invoiceRepository,"columns":_vm.columns,"no-auto-fetch":""},on:{"select":_vm.goToInvoice},scopedSlots:_vm._u([{key:"level0-cell",fn:function(scope){return [(scope.columnIndex === 0)?_c('div',[_vm._v(" "+_vm._s(((scope.row.invoicePrefix) + "-" + (scope.row.invoiceNumber)))+" ")]):_vm._e(),(scope.columnIndex === 1)?_c('div',[_vm._v(" "+_vm._s(_vm.getFormatStatus(scope.row.status))+" ")]):_vm._e(),(scope.columnIndex === 2)?_c('div',[_vm._v(" "+_vm._s(scope.row.client.name)+" ")]):_vm._e(),(scope.columnIndex === 3)?_c('div',[_vm._v(" "+_vm._s(_vm.getFormatDateRange(scope.row))+" ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }