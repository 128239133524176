<template>
  <div class="EditTeamPage edit-team-page">
    <div class="header">
      <div>
        <span class="page-title">
          {{ (team.exists()) ? 'Edit Team' : 'New Team' }}
        </span>
      </div>
      <div>
        <el-button
          :disabled="saving"
          type="plain"
          @click.prevent="cancel"
        >
          Cancel
        </el-button>
        <el-button
          :disabled="formInvalid || saving"
          type="success"
          title="Click to save"
          @click.prevent="save"
        >
          <i
            v-if="saving"
            class="el-icon-loading"
          />
          Save
        </el-button>
      </div>
    </div>
    <el-card class="wrapper">
      <div class="form">
        <div>
          <label>
            Name
          </label>
          <el-input
            v-model="team.name"
            :class="{ 'has-error': $v.team.name.$error }"
            placeholder="Enter team name"
            @input="$v.team.name.$touch()"
          />
          <div
            v-if="$v.team.name.$error"
            class="errors"
          >
            <span
              v-if="!$v.team.name.required"
              class="error-message"
            >
              Field is required
            </span>
          </div>
        </div>
        <el-tabs v-model="activeTabName">
          <el-tab-pane
            label="Members"
            name="members"
          >
            <el-autocomplete
              v-model="queryString"
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              placeholder="Search members"
              value-key="name"
              class="members-search-input"
              @select="addMember"
            >
              <template slot-scope="{ item }">
                <span>
                  {{ item.name }}
                </span>
                <span
                  class="green-text"
                >
                  ({{ item.email.substr(0, queryString.length) }}
                </span>
                <span class="gray-text">
                  {{ item.email.slice(queryString.length, item.email.length) }})
                </span>
              </template>
            </el-autocomplete>
            <div class="transfer">
              <el-table
                :data="team.users"
                :default-sort="{ prop: 'name' }"
                stripe
              >
                <el-table-column
                  prop="name"
                  label="Name"
                  sortable
                />
                <el-table-column
                  label="Billable Rate"
                  width="150"
                >
                  <template slot-scope="scope">
                    <el-input-number
                      v-model="scope.row.options.billableRate"
                      :step="1"
                      :min="0"
                      size="mini"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="Payable Rate"
                  width="150"
                >
                  <template slot-scope="scope">
                    <el-input-number
                      v-model="scope.row.options.payableRate"
                      :step="1"
                      :min="0"
                      size="mini"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="Daily Limit (hours)"
                  width="150"
                >
                  <template slot-scope="scope">
                    <el-input-number
                      v-model="scope.row.options.timeLimit"
                      :step="1"
                      :min="0"
                      :max="24"
                      size="mini"
                    />
                  </template>
                </el-table-column>
                <el-table-column width="80">
                  <template slot-scope="scope">
                    <el-button
                      :disabled="scope.row.uuid === user.uuid"
                      type="danger"
                      plain
                      size="mini"
                      icon="el-icon-delete"
                      @click="removeMember(scope.row.uuid)"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div>
          <el-button
            v-if="team.exists()"
            type="text"
            class="delete_button"
            @click="showConfirmModal = true"
          >
            Delete team
          </el-button>
        </div>
        <!-- Confirm delete team modal form -->

        <el-dialog
          v-if="team.exists()"
          :visible.sync="showConfirmModal"
          title="Delete team"
          width="30%"
        >
          <p>
            It will not be undone. Please
            enter team name to continue:
            <br>
            ({{ team.name }})
          </p>
          <el-input
            v-model="teamName"
            placeholder="Enter team name"
          />
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              @click.prevent="showConfirmModal = false"
            >
              Cancel
            </el-button>
            <el-button
              :disabled="teamName !== team.name"
              type="danger"
              @click.prevent="deleteTeam"
            >
              Delete
            </el-button>
          </span>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { Team, UserRepository, TeamRepository } from '@timeragent/core';

import { mapGetters } from 'vuex';
import { showError, showSuccess } from '@/notification';
import notification from '../../mixins/notification';
import loading from '../../mixins/loading';

const validations = {
  team: {
    name: {
      required,
    },
  },
};

export default {
  name: 'EditTeamPage',

  validations,

  props: {
    teamUuid: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    mixins: [notification, loading],
    team: new Team(),
    teamRepository: new TeamRepository(),
    showConfirmModal: false,
    queryString: '',
    queryUsers: [],
    activeTabName: 'members',
    teamName: '',
    saving: false,
    userRepository: new UserRepository(),
  }),

  computed: {
    ...mapGetters(['user']),
    formInvalid() {
      return this.$v.$invalid;
    },
  },

  async created() {
    switch (this.$route.name) {
    case 'editTeam':
      this.team = await this.teamRepository.one(this.$route.params.teamUuid);
      break;
    case 'newTeam': {
      console.log(this.user);
      // @ts-ignore
      const { user } = this;

      this.$set(user, 'options', {
        billableRate: 0,
        payableRate: this.user.payableRate || 0,
        timeLimit: 0,
      });
      this.team.users.push(user);
      break;
    }
    default:
    }
    this.userRepository.many({ organizationUuid: this.$route?.params?.organizationUuid });
  },

  methods: {
    querySearch(queryString, cb) {
      this.queryUsers = this.userRepository.dataset.all();
      const members = Object.assign(this.queryUsers);
      const results = queryString ? members.filter(this.createFilter(queryString)) : [];

      // call callback function to return suggestions
      cb(results);
    },

    createFilter(queryString) {
      return member => (
        member.email.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        && member.email !== this.user.email
        && !this.team.users.find(u => u.email === member.email)
      );
    },

    addMember(user) {
      this.queryString = '';
      if (this.team.users.find(member => member.uuid === user.uuid)) {
        return;
      }
      this.$set(user, 'options', {
        billableRate: 0,
        payableRate: user.payableRate || 0,
        timeLimit: 0,
      });
      this.team.users.push(user);
    },

    removeMember(userUuid) {
      if (userUuid === this.user.uuid) return;
      this.team.users = this.team
        .users
        .filter(teamUser => teamUser.uuid !== userUuid);
    },

    async save() {
      if (this.$v.$invalid) return;
      this.saving = true; // start spinner

      try {
        await this.team.save();

        showSuccess(this.team.exists() ? 'Team saved successfully' : 'Team created successfully');
        this.$router.push({
          name: 'teams',
          params: {
            // @ts-ignore
            organizationUuid: this.organizationUuid,
          },
        });
      } catch (e) {
        console.error(e);
        showError(e.message);
        this.saving = false;
      }
    },

    async deleteTeam() {
      if (this.teamName !== this.team.name) return;
      try {
        await this.team.delete();

        showSuccess('Team deleted successfully');
        this.$router.go(-1);
      } catch (e) {
        showError(e.message);
      }
      this.showConfirmModal = false;
    },

    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>
  .edit-team-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .wrapper {
    max-width: 1723px;
    width: 100%;

    & >>> {
      .el-card__body {
        display: flex;
        justify-content: center;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1723px;
  }

  .form {
    width: 100%;
    max-width: 1120px;
  }

  .transfer {
    margin-top: 20px;
  }

  .el-tabs {
    margin-top: 30px;
  }

  .delete_button {
    color: #FA5555;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

  .green-text {
    margin-right: -3px;
  }

  .members-search-input {
    width: 100%;
  }

</style>
