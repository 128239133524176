import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/reports`,
  name: 'reports',
  component: () => import('../components/pages/ReportsPage.vue'),
  meta: {
    name: 'Reports',
  },
};
