import {
  InvoiceRepository,
  Organization,
  OrganizationMemberRepository,
  ProjectRepository,
  ReportDataRepository,
  TaskRepository,
  TeamRepository,
  User,
  UserRepository,
} from '@timeragent/core';
import moment from 'moment';
import { Route } from 'vue-router';
import { ActionTree } from 'vuex';
import { IUIState } from '@/store/state';
import * as types from './mutation-types';

// noinspection JSUnusedGlobalSymbols
export default {
  async getMe({ commit, state, dispatch }: any, { updateOrg, updateUser } = {}) {
    if (state.user.exists()) {
      if (!updateOrg || !updateUser) return state.user;
    }

    const user = await (new UserRepository()).one();

    dispatch('setUser', user);

    return user;
  },

  setActiveTimeEntry({ commit }: any, activeTimeEntry: object) {
    commit(types.SET_ACTIVE_TIME_ENTRY, activeTimeEntry);
  },

  getTeams({ commit, state }, { organizationUuid }) {
    if (state.teamRepository.exists()) {
      return state.teamRepository;
    }

    const teamRepository = new TeamRepository();

    teamRepository.many({
      organizationUuid,
    });

    commit(types.SET_TEAM_REPOSITORY, teamRepository);

    return teamRepository;
  },

  getInvoices({ commit, state }, { organizationUuid }) {
    const invoiceRepository = new InvoiceRepository();

    invoiceRepository.many({
      organizationUuid,
    });

    commit('SET_INVOICE_REPOSITORY', invoiceRepository);

    return invoiceRepository;
  },

  getOneInvoice({ commit }, { uuid }) {
    const invoiceRepository = new InvoiceRepository();

    invoiceRepository.one(uuid);

    commit('SET_INVOICE_REPOSITORY', invoiceRepository);

    return invoiceRepository;
  },

  async getReportData({ commit, state, dispatch }, { organizationUuid, filters }) {
    if (state.reportDataRepository.exists()) {
      return state.reportDataRepository;
    }

    const reportDataRepository = new ReportDataRepository();

    await reportDataRepository.fetchData({
      filters: {
        organizationUuid,
        ...filters,
      },
    });
    commit('SET_REPORT_DATA_REPOSITORY', reportDataRepository);

    return reportDataRepository;
  },

  getOrganizationMembers({ commit, state }, { organizationUuid }) {
    if (state.organizationMemberRepository.exists()) {
      return state.organizationMemberRepository;
    }

    const organizationMemberRepository = new OrganizationMemberRepository();

    organizationMemberRepository.many({
      organizationUuid,
    });

    commit(types.SET_ORGANIZATION_MEMBER_REPOSITORY, organizationMemberRepository);

    return organizationMemberRepository;
  },

  getProjects({ commit, state, dispatch }, {
    withTasks = false,
    organizationUuid,
  }) {
    if (state.projectRepository.exists()) {
      return state.projectRepository;
    }

    const projectRepository = new ProjectRepository();

    projectRepository.many({
      withTasks,
      filters: {
        organizationUuid,
      },
    });

    commit(types.SET_PROJECT_REPOSITORY, projectRepository);

    return projectRepository;
  },

  async getTimeEntries({ commit, state, dispatch }, {
    filters: {
      organizationUuid,
    },
  }) {
    await state.timeEntryRepository.many({
      filters: {
        organizationUuid,
      },
    });

    commit(types.SET_TIME_ENTRIES, state.timeEntryRepository);

    return state.timeEntryRepository;
  },

  setUser({ commit, state }, user: User | object) {
    const localUser = user instanceof User ? user : new User(user);
    let curOrg = localUser.organizations.find(
      // @ts-ignore
      org => (state.route as Route).params.organizationUuid === org.uuid,
    ) || localUser.organizations[0];

    curOrg = new Organization(curOrg);

    commit(types.SET_ME, localUser);
    commit(types.SET_ORGANIZATION, curOrg);
    commit(types.SET_PROJECT_REPOSITORY, new ProjectRepository(curOrg.userProjects));
  },

  setOrganization({ commit, state }, organizationUuid) {
    let curOrg = state.user.organizations.find(
      org => organizationUuid === org.uuid,
    ) || state.user.organizations[0];

    curOrg = new Organization(curOrg);

    commit(types.SET_ORGANIZATION, curOrg);
    commit(types.SET_PROJECT_REPOSITORY, new ProjectRepository(curOrg.userProjects));
  },

  setCurrentDate({ commit }, date) {
    commit(types.SET_CURRENT_DATE, date);
  },

  getCalendarTasks({ commit, state }, { dateRange, userUuid, organizationUuid }) {
    const calendarTasks = new TaskRepository();

    calendarTasks.many({
      dateRange,
      userUuid,
      organizationUuid,
    });
    commit(types.SET_CALENDAR_TASKS, calendarTasks);
  },
  removeLogoUrl({ commit }, stateField) {
    commit(types.REMOVE_LOGO_URL, stateField);
  },

  // #########################
  //      NOT REFACTORED
  // #########################

  setTimerId({ commit }, { timerId }) {
    commit(types.SET_TIMER_ID, timerId);
  },

  updateUser(context, { user }) {
    const localUser = user;

    delete localUser.name;
    delete localUser.organizations;
    delete localUser.currencyRates;
    delete localUser.tags;
    delete localUser.password;
  },

  clearUser(context) {
    context.commit(types.CLEAR_USER);
  },

  clearTeam(context) {
    context.commit(types.CLEAR_TEAM);
  },

  clearTeams(context) {
    context.commit(types.CLEAR_TEAMS);
  },

  setProjectForChart({ commit }, selectedProjectsForChart) {
    commit(types.SET_PROJECT_FOR_CHART, selectedProjectsForChart);
  },

  setTimeEntries({ commit }, timeEntries) {
    commit(types.SET_TIME_ENTRIES, timeEntries);
  },

  deleteTimeEntry({ commit }, timeEntry) {
    commit(types.DELETE_TIME_ENTRY, timeEntry);
  },

  setDateForChart({ commit }, dateForChart) {
    const chartData = dateForChart;

    chartData[1] = `${moment(dateForChart[1]).format('YYYY-MM-DD 23:59:59')}`;
    commit(types.SET_DATE_FOR_CHART, chartData);
  },
  clearSelectedProjectsForChart({ commit }) {
    commit(types.CLEAR_SELECTED_PROJECTS_FOR_CHART);
  },

  clearOrganization({ commit }) {
    commit(types.CLEAR_ORGANIZATION);
  },

  clearOrganizationMembers(context) {
    context.commit(types.CLEAR_ORGANIZATION_MEMBERS);
  },

  logout() {
    localStorage.clear();
  },

  startLoading({ commit }) {
    document.body!.classList.remove('loading');

    commit('START_LOADING');
  },

  stopLoading({ commit }) {
    document.body!.classList.add('loading');

    commit('STOP_LOADING');
  },

  clearProjects({ commit }) {
    commit(types.CLEAR_PROJECTS);
  },

  moduleRegistered({ commit }, moduleName: string) {
    commit(types.MODULE_REGISTERED, moduleName);
  },
} as ActionTree<IUIState, any>;
