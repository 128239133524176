import moment from 'moment';
import { Project, TimeEntry, User } from '@timeragent/core';
import { GetterTree } from 'vuex';
import { cloneObject } from '@/helpers';
import { IUIState } from '@/store/state';

// noinspection JSUnusedGlobalSymbols
export default {
  loading: state => state.loading,

  isAlpha: () => localStorage.getItem('blade-runner'),

  date: state => state.date,

  user: state => state.user,

  taskTags: state => state.taskTags,

  teamRepository: state => state.teamRepository,

  projectRepository: state => state.projectRepository,

  clientRepository: state => state.clientRepository,

  timeEntryRepository: state => state.timeEntryRepository,

  invoiceRepository: state => state.invoiceRepository,

  reportDataRepository: state => state.reportDataRepository,

  organizationMemberRepository: state => state.organizationMemberRepository,

  // #########################
  //      NOT REFACTORED
  // #########################
  timerId: state => state.timerId,

  getActiveTimeEntry: state => state.activeTimeEntry,

  oldActiveTask: state => state.oldActiveTask,

  organizationTeams: state => state.organizationTeams,

  team: state => state.team,

  ownTeams: state => state.ownTeams,

  ownUsers: state => state.ownUsers,

  allUsers: state => state.allUsers,

  organization: state => state.organization,

  organizations: state => state.organizations,

  currencies: state => state.currencies,

  defaultCurrency: state => state.defaultCurrency,

  organizationMembers: state => state.organizationMembers,

  organizationInvites: state => state.organizationInvites,

  existsEmail: state => state.existsEmail,

  selectedProjectsForChart: state => state.selectedProjectsForChart,

  dateForChart: state => state.dateForChart,

  // reportData: state => state.reportData,

  calendarTasks: state => state.calendarTasks,

  /**
   * Filters projects for all charts based on date and project filter
   *
   * @param {array<String, String>} dateForChart
   * @param {array<String>} selectedProjectsForChart
   * @param {array<Object>} projects
   * @param {object} user
   * @returns {array}
   */
  filteredProjectsForCharts: ({
    dateForChart,
    selectedProjectsForChart,
    projectRepository,
    user,
  }) => cloneObject(projectRepository.dataset.all()).map(project => {
    if (!selectedProjectsForChart.find(pr => pr.uuid === project.uuid)) {
      return null;
    }

    const tasks = (project.tasks || []).map(task => {
      const timeEntries = task.timeEntries?.filter(timeEntry => (timeEntry.active && task.userUuid === user.uuid && moment(dateForChart[0], moment.defaultFormat).toDate()
        <= moment(timeEntry.startTime, moment.defaultFormat).toDate() && moment(dateForChart[1], moment.defaultFormat).toDate()
        >= moment().toDate())
        || (task.userUuid === user.uuid
          && moment(dateForChart[0], moment.defaultFormat).toDate()
          <= moment(timeEntry.startTime, moment.defaultFormat).toDate()
          && moment(dateForChart[1], moment.defaultFormat).toDate()
          >= moment(timeEntry.endTime, moment.defaultFormat).toDate()));

      return Array.isArray(timeEntries) && timeEntries.length ? Object.assign(task, { timeEntries }) : null;
    }).filter(item => item);

    return Object.assign(project, { tasks });
  }).filter(item => item),

  columnChartData: ((_, { filteredProjectsForCharts, dateForChart }: { filteredProjectsForCharts: Project[], dateForChart: [string, string] }) => {
    const columnChartData: any[] = [];
    const from = moment(dateForChart[0], 'YYYY-MM-DD H:mm:ss');
    const to = moment(dateForChart[1], 'YYYY-MM-DD H:mm:ss');
    const now = from.clone();
    const duration = moment.duration(moment(dateForChart[1], 'YYYY-MM-DD H:mm:ss')
      .diff(moment(dateForChart[0], 'YYYY-MM-DD H:mm:ss')));
    const durationDays = Math.round(moment.duration(duration, 'milliseconds').asDays());

    while (now.isSame(to, 'day')) {
      columnChartData.push([now.format('YYYY-MM-DD H:mm:ss')]);
      now.add(1, 'hours');
    }
    while (now.isBefore(to) && !from.isSame(to, 'day') && durationDays === 365) {
      columnChartData.push([now.format('YYYY-MMM')]);
      now.add(1, 'months');
    }
    while (now.isBefore(to) && !from.isSame(to, 'day') && durationDays !== 365) {
      columnChartData.push([now.format('YYYY-MM-DD')]);
      now.add(1, 'days');
    }
    columnChartData.forEach(arr => {
      filteredProjectsForCharts.forEach(project => {
        let totalTime = 0;

        project.tasks?.forEach(task => {
          task.timeEntries?.each(time => {
            let timeEntryEnd = time.endTime;

            if (time.active === true) {
              timeEntryEnd = moment().toDate();
            }
            if (arr[0] === moment(time.startTime).format('YYYY-MM-DD')) {
              const durationByDays = moment.duration(moment(timeEntryEnd, 'YYYY-MM-DD H:mm:ss')
                .diff(moment(time.startTime, 'YYYY-MM-DD H:mm:ss')));
              const seconds = moment.duration(durationByDays, 'milliseconds').asSeconds();

              totalTime += seconds;
            }
            if (moment(arr[0]).format('YYYY-MM-DD')
              === moment(time.startTime).format('YYYY-MM-DD')
              && moment(arr[0]).format('HH') > moment(time.startTime).format('HH')
              && moment(arr[0]).format('HH') < moment(timeEntryEnd).format('HH')) {
              totalTime = 60;
            }
            if (moment(arr[0]).format('YYYY-MM-DD')
              === moment(time.startTime).format('YYYY-MM-DD')
              && moment(arr[0]).format('HH') === moment(timeEntryEnd).format('HH')
              && moment(time.startTime).format('HH') !== moment(timeEntryEnd).format('HH')) {
              totalTime += parseFloat(moment(timeEntryEnd).format('m'));
            }
            if (moment(arr[0]).format('YYYY-MM-DD')
              === moment(time.startTime).format('YYYY-MM-DD')
              && moment(arr[0]).format('HH') === moment(time.startTime).format('HH')
              && moment(arr[0]).format('HH') < moment(timeEntryEnd).format('HH')) {
              totalTime += 60 - parseFloat(moment(time.startTime).format('m'));
            }
            if (moment(arr[0]).format('YYYY-MM-DD')
              === moment(time.startTime).format('YYYY-MM-DD')
              && moment(arr[0]).format('HH') === moment(time.startTime).format('HH')
              && moment(arr[0]).format('HH') === moment(timeEntryEnd).format('HH')) {
              totalTime += +moment(timeEntryEnd).format('m') - +moment(time.startTime).format('m');
            }
            if (arr[0] === moment(time.startTime).format('YYYY-MMM')) {
              const durationByDays = moment.duration(moment(timeEntryEnd, 'YYYY-MM-DD H:mm:ss')
                .diff(moment(time.startTime, 'YYYY-MM-DD H:mm:ss')));
              const seconds = moment.duration(durationByDays, 'milliseconds').asSeconds();

              totalTime += seconds;
            }
          });
        });
        if (moment(dateForChart[0]).format('YYYY-MM-DD')
          === moment(dateForChart[1]).format('YYYY-MM-DD')) {
          arr.push({
            v: totalTime,
            f: `${totalTime}min`,
          });
        }
        if (moment(dateForChart[0]).format('YYYY-MM-DD')
          !== moment(dateForChart[1]).format('YYYY-MM-DD')) {
          const hours = Math.floor(moment.duration(totalTime, 'seconds').asHours());
          const minutes = moment.duration(totalTime, 'seconds').minutes();
          const totalHours = parseFloat(`${hours}.${minutes}`);

          arr.push({
            v: totalHours,
            f: `${Math.floor(moment.duration(totalTime, 'seconds').asHours())}h ${moment.duration(totalTime, 'seconds').minutes()}m`,
          });
        }
      });
    });

    return columnChartData;
  }),

  tableChartData: ((_, { filteredProjectsForCharts, user }: { filteredProjectsForCharts: Project[], user: User }) => {
    const lengthTableChart: any[] = [];
    let userNow: User | undefined;
    const finder = (someUser: User) => someUser.uuid === user.uuid;

    filteredProjectsForCharts.forEach(project => {
      (project.tasks || []).forEach(task => {
        task.timeEntries?.each(timeEntry => {
          let timeEntryEnd = timeEntry.endTime;

          if (timeEntry.active === true) {
            timeEntryEnd = moment().toDate();
          }
          const duration = moment.duration(moment(timeEntryEnd, 'YYYY-MM-DD H:mm:ss')
            .diff(moment(timeEntry.startTime, 'YYYY-MM-DD H:mm:ss')));
          const seconds = moment.duration(duration, 'milliseconds').asSeconds();

          if (project?.users?.length) {
            userNow = project.users.find(finder);
          } else {
            const teamMy = project.teams?.find(team => team.users.find(finder));

            userNow = teamMy?.users?.find(finder);
          }
          const money = seconds * ((userNow?.options?.payableRate || 0) / 3600);

          const exIdx = lengthTableChart.findIndex(el => el[1].description
            === task.description && el[0] === task.project?.name && task.uuid === el[1].uuid);

          if (exIdx >= 0) {
            lengthTableChart[exIdx][3] += seconds;
            lengthTableChart[exIdx][5] += money;

            return;
          }

          lengthTableChart.push([
            project.name,
            task,
            task.eta,
            seconds,
            project.currency?.symbol,
            money,
          ]);
        });
      });
    });

    return lengthTableChart;
  }),

  productivityChartData: ((_, {
    filteredProjectsForCharts,
    user,
    dateForChart,
    totalsTimeEtaData,
  }: {
    filteredProjectsForCharts: Project[],
    user: User,
    dateForChart: [string, string],
    totalsTimeEtaData: { totalSeconds: number },
  }) => {
    const productivityChart = {
      workedOut: 0,
      leftWorked: 0,
      workedExtra: 0,
      percentage: 0,
    };
    let limitHours = 0;

    filteredProjectsForCharts.forEach(project => {
      (project.teams || []).forEach(team => {
        team.users.forEach(usr => {
          if (usr.uuid === user.uuid) {
            limitHours += usr.options.timeLimit;
          }
        });
      });
      (project.users || []).forEach(usr => {
        if (usr.uuid === user.uuid) {
          limitHours += usr.options.timeLimit;
        }
      });
    });
    let workedDays = moment(dateForChart[1], 'YYYY-MM-DD H:mm:ss')
      .businessDiff(moment(dateForChart[0], 'YYYY-MM-DD H:mm:ss'));

    if (
      moment(dateForChart[0], 'YYYY-MM-DD H:mm:ss').format('DD-MM-YYYY')
      === moment(dateForChart[1], 'YYYY-MM-DD H:mm:ss').format('DD-MM-YYYY')
    ) {
      workedDays = 1;
    }
    const limitSeconds = (limitHours || 8) * 3600;
    const mustWorkSeconds = workedDays * limitSeconds;

    productivityChart.workedOut = totalsTimeEtaData.totalSeconds;
    productivityChart.percentage = Math.floor((100 * productivityChart.workedOut)
      / mustWorkSeconds);
    if (mustWorkSeconds > productivityChart.workedOut) {
      productivityChart.leftWorked = mustWorkSeconds - productivityChart.workedOut;
      if (productivityChart.workedOut > 60) {
        productivityChart.leftWorked += 60;
      }
    }
    if (mustWorkSeconds < productivityChart.workedOut) {
      productivityChart.workedExtra = productivityChart.workedOut - mustWorkSeconds;
      productivityChart.workedOut = mustWorkSeconds;
    }

    return productivityChart;
  }),

  totalsTimeEtaData: ((_, { filteredProjectsForCharts }: { filteredProjectsForCharts: Project[] }) => {
    const totalData = {
      totalSeconds: 0,
      totalEta: 0,
    };

    filteredProjectsForCharts.forEach(project => {
      project.tasks?.forEach(task => {
        totalData.totalEta += task.eta;
        task.timeEntries?.each(timeEntry => {
          let timeEntryEnd = timeEntry.endTime;

          if (timeEntry.active === true) {
            timeEntryEnd = moment().toDate();
          }
          const duration = moment.duration(moment(timeEntryEnd, 'YYYY-MM-DD H:mm:ss')
            .diff(moment(timeEntry.startTime, 'YYYY-MM-DD H:mm:ss')));
          const seconds = moment.duration(duration, 'milliseconds').asSeconds();

          totalData.totalSeconds += seconds;
        });
      });
    });

    return totalData;
  }),

  totalMoneyByCurrency: ((_, { filteredProjectsForCharts, user }: { filteredProjectsForCharts: Project[], user: User }) => {
    let userNow: User | undefined;
    const totalMoney: { [index: string]: number } = {};
    let money = 0;
    const finder = (someUser: User) => someUser.uuid === user.uuid;

    function calculateMoney(project: Project, timeEntry: TimeEntry) {
      let timeEntryEnd = timeEntry.endTime;

      if (timeEntry.active) {
        timeEntryEnd = moment().toDate();
      }
      const duration = moment.duration(moment(timeEntryEnd, 'YYYY-MM-DD H:mm:ss')
        .diff(moment(timeEntry.startTime, 'YYYY-MM-DD H:mm:ss')));
      const seconds = moment.duration(duration, 'milliseconds').asSeconds();

      if (project.users?.length) {
        userNow = project.users.find(finder);
      } else {
        const teamMy = project.teams?.find(team => team.users.find(finder));

        userNow = teamMy?.users?.find(finder);
      }

      money = seconds * ((userNow?.options?.payableRate || 0) / 3600);

      totalMoney[project.currency?.symbol || 'USD'] = money + (totalMoney[project?.currency?.symbol || 'USD'] || 0);
    }

    filteredProjectsForCharts.forEach(project => {
      project.tasks?.forEach(task => {
        task.timeEntries?.each(timeEntry => calculateMoney(project, timeEntry));
      });
    });

    return totalMoney;
  }),

  consolidateAmountsData: ((_, { totalMoneyByCurrency, user }: { totalMoneyByCurrency: Project, user: User }) => {
    let consolidateAmounts = 0;

    if (user.consolidateAmounts === true) {
      Object.entries(totalMoneyByCurrency).forEach(([key, value]) => {
        const object = user.currencyRates.rates.find(obj => obj.symbol === key) || { rate: 1 };

        consolidateAmounts += value / object.rate;
      });
    }

    return consolidateAmounts;
  }),
} as GetterTree<IUIState, any>;
