export default {
  path: '/change-password/:verificationToken',
  name: 'changePassword',
  component: () => import('../components/pages/ChangePasswordPage.vue'),
  meta: {
    name: 'Recovery Password',
    guest: true,
  },
  props: true,
};
