<template>
  <div>
    <el-main>
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <el-card>
            <div class="demo-app">
              <div class="demo-app-top">
                <button @click="toggleWeekends">
                  toggle weekends
                </button>
                <button @click="gotoPast">
                  go to a date in the past
                </button>
                (also, click a date/time to add an event)
              </div>
              <FullCalendar
                ref="fullCalendar"
                class="demo-app-calendar"
                :event-class-name="[
                  'myclassname',
                  'otherclassname',
                ]"
                default-view="dayGridMonth"
                theme-system="minty"
                :header="{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                }"
                :event-limit="4"
                :plugins="calendarPlugins"
                :weekends="calendarWeekends"
                :events="calendarData"
                :now-indicator="true"
                :droppable="true"
                :editable="true"
                @dateClick="handleDateClick"
                @datesRender="setData"
              />
            </div>
            <CreateEvent
              v-if="showForm"
              @cancel-item="cancelItem"
              @buy-item="buyItem"
            />
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

// FullCalendar plugins
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

export default {
  name: 'CalendarPage',

  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CreateEvent: () => import('./CreateEvent.vue'),
  },
  data: () => ({
    dateRange: [],
    showForm: false,
    calendarPlugins: [
      // plugins must be defined in the JS
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin, // needed for dateClick
    ],
    calendarWeekends: true,
    calendarData: [
      // initial event data
      { title: 'event 1', date: '2021-05-25' },
      { title: 'event 2', date: '2021-05-23' },
      { title: 'event 2', date: '2021-05-26' },
      { title: 'event 2', date: '2021-05-23' },
      { title: 'event 2', date: '2021-05-22' },
      { title: 'event 2', date: '2021-05-21' },
    ],
  }),

  computed: {
    ...mapGetters([
      'user',
      'calendarTasks',
    ]),
    tasks() {
      return this.calendarTasks.dataset;
    },
    fetchTasks() {
      const tasks = [];

      this.tasks.all().forEach(task => {
        task.timeEntries.dataset.all().forEach(time => {
          tasks.push({
            title: task.description,
            start: time.startTime,
            end: time.endTime,
          });
        });
      });

      return tasks;
    },
    // calendarData() {
    //   return [
    //     ...this.fetchTasks,
    //   ];
    // },
  },

  methods: {
    ...mapActions([
      'getCalendarTasks',
    ]),
    setData(arg) {
      if (moment(arg.view.activeStart).format('YYYY-MM-DD H:mm:ss') !== this.dateRange[0]
        && moment(arg.view.activeEnd).format('YYYY-MM-DD H:mm:ss') !== this.dateRange[1]) {
        this.dateRange = [
          moment(arg.view.activeStart).format('YYYY-MM-DD H:mm:ss'),
          moment(arg.view.activeEnd).format('YYYY-MM-DD H:mm:ss'),
        ];
        this.getCalendarTasks({
          dateRange: this.dateRange,
          userUuid: this.user.uuid,
          organizationUuid: this.$route.params.organizationUuid,
        });
      }
    },
    toggleWeekends() {
      this.calendarWeekends = !this.calendarWeekends; // update a property
    },
    gotoPast() {
      const calendarApi = this.$refs.fullCalendar.getApi(); // from the ref="..."

      calendarApi.gotoDate('2000-01-01'); // call a method on the Calendar object
    },
    handleDateClick(arg) {
      console.log(`Would you like to add an event to ${arg.dateStr} ?`);
      console.log(arg);
      this.calendarData.push({
        // add new event data
        title: 'New Event',
        start: arg.date,
        allDay: arg.allDay,
      });
    },
    cancelItem() {
      this.showForm = false;
    },
  },
};
</script>

<style
  lang="stylus"
  scoped
>
  ::v-deep .fc-unthemed th,
  ::v-deep .fc-unthemed td {
    border-color: transparent;
    border-width: 5px;
  }

  ::v-deep .fc-unthemed td.fc-today {
    background: #a9c9ef9c;
    border-radius: 15px;
  }

  ::v-deep .myclassname {
    background: rgba(63, 129, 216, 0.4);
    color: #000;
  }

  ::v-deep .fc-content {
    border-left: 2px solid #3788d8;
    padding-left: 8px;
    margin: 5px;
  }

  ::v-deep .fc-day {
    background: aliceblue;
    border-radius: 15px;
    margin: 5px;
  }

  ::v-deep .fc-row {
    margin-top: 5px;
  }

  body {
    background-color: #efefef;
  }

  .demo-app {
    font-size: 14px;
    color: #6A717C;
  }

  .demo-app-top {
    margin: 0 0 3em;
  }

  .demo-app-calendar {
    margin: 0 auto;
    max-width: 900px;
  }
</style>
