import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/teams`,
  name: 'teams',
  component: () => import('../components/pages/TeamsPage.vue'),
  meta: {
    name: 'Teams',
  },
};
