export default {
  path: '/login',
  name: 'login',
  component: () => import('../components/pages/LoginPage.vue'),
  meta: {
    name: 'Login',
    guest: true,
  },
  props: true,
};
