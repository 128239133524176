var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ProfilePage"},[_c('el-main',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('el-row',[_c('el-col',{attrs:{"span":22,"offset":1}},[_c('div',{staticClass:"pull-right"},[_c('el-button',{attrs:{"type":"plain"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")]),_c('el-button',{attrs:{"disabled":_vm.formInvalid,"type":"success"},on:{"click":_vm.updateUser}},[_vm._v(" Save ")])],1),_c('span',{staticClass:"page-title"},[_vm._v(" My Profile ")]),_c('el-col',{attrs:{"span":24}},[_c('el-card',[_c('el-row',[_c('el-col',{attrs:{"span":16,"offset":4}},[_c('el-row',[_c('label',[_vm._v(" Logo ")]),_c('UploadPicture',{attrs:{"circle":"","field-from-state":_vm.user},on:{"logoFile":_vm.applyLogo}})],1),_c('el-row',[_c('label',[_vm._v(" First Name ")]),_c('el-input',{class:{
                      'has-error': _vm.$v.localUser.firstName.$error
                    },attrs:{"placeholder":"Enter your name"},on:{"input":function($event){return _vm.$v.localUser.firstName.$touch()}},model:{value:(_vm.localUser.firstName),callback:function ($$v) {_vm.$set(_vm.localUser, "firstName", $$v)},expression:"localUser.firstName"}}),(_vm.$v.localUser.firstName.$error)?_c('div',{staticClass:"errors"},[(!_vm.$v.localUser.firstName.required)?_c('span',{staticClass:"error-message"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()],1),_c('el-row',[_c('label',[_vm._v(" Last Name ")]),_c('el-input',{class:{
                      'has-error': _vm.$v.localUser.lastName.$error
                    },attrs:{"placeholder":"Enter your name"},on:{"input":function($event){return _vm.$v.localUser.lastName.$touch()}},model:{value:(_vm.localUser.lastName),callback:function ($$v) {_vm.$set(_vm.localUser, "lastName", $$v)},expression:"localUser.lastName"}}),(_vm.$v.localUser.lastName.$error)?_c('div',{staticClass:"errors"},[(!_vm.$v.localUser.lastName.required)?_c('span',{staticClass:"error-message"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()],1),_c('el-row',[_c('label',[_vm._v(" Email ")]),_c('el-input',{class:{
                      'has-error': _vm.$v.localUser.email.$error
                    },attrs:{"placeholder":"Enter your email","disabled":""},on:{"input":function($event){return _vm.$v.localUser.email.$touch()},"blur":_vm.validateEmail},model:{value:(_vm.localUser.email),callback:function ($$v) {_vm.$set(_vm.localUser, "email", $$v)},expression:"localUser.email"}}),(_vm.$v.localUser.email.$error)?_c('div',{staticClass:"errors"},[(!_vm.$v.localUser.email.required)?_c('span',{staticClass:"error-message"},[_vm._v(" Field is required ")]):_vm._e(),(!_vm.$v.localUser.email.isEmail)?_c('span',{staticClass:"error-message"},[_vm._v(" Invalid email ")]):_vm._e(),(!_vm.$v.localUser.email.isUniqueEmail)?_c('span',{staticClass:"error-message"},[_vm._v(" Email is used by another user ")]):_vm._e()]):_vm._e()],1),_c('el-row',[_c('el-checkbox',{attrs:{"disabled":_vm.currencyLoading},model:{value:(_vm.localUser.consolidateAmounts),callback:function ($$v) {_vm.$set(_vm.localUser, "consolidateAmounts", $$v)},expression:"localUser.consolidateAmounts"}},[_vm._v(" Consolidate amounts to a single currency ")])],1),(_vm.localUser.consolidateAmounts)?[_c('el-row',[_c('label',[_vm._v(" Preferred currency ")]),_c('el-select',{attrs:{"disabled":_vm.currencyLoading,"placeholder":_vm.currencyLoading ? 'Loading...' : 'Select',"value-key":"uuid","autocomplete":"","filterable":""},model:{value:(_vm.localUser.currency),callback:function ($$v) {_vm.$set(_vm.localUser, "currency", $$v)},expression:"localUser.currency"}},_vm._l((_vm.currencies),function(currency){return _c('el-option',{key:currency.uuid,attrs:{"label":currency.code,"value":currency}})}),1)],1)]:_vm._e(),_c('hr'),_c('el-row',[_c('label',[_vm._v(" Current password ")]),_c('br'),_c('el-input',{class:{
                      'has-error' : _vm.$v.currentPassword.$error
                        || _vm.errors.currentPassword
                    },attrs:{"type":"password","placeholder":"Enter old password"},on:{"input":function($event){return _vm.$v.currentPassword.$touch()}},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),(_vm.$v.currentPassword.$error)?_c('div',{staticClass:"errors"},[(!_vm.$v.localUser.currentPassword.required)?_c('span',{staticClass:"error-message"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e(),(_vm.errors.currentPassword)?_c('div',{staticClass:"errors"},_vm._l((_vm.errors.currentPassword),function(message){return _c('span',{key:message,staticClass:"error-message"},[_vm._v(" "+_vm._s(message)+" ")])}),0):_vm._e()],1),_c('el-row',[_c('label',[_vm._v("New password")]),_c('br'),_c('el-input',{class:{
                      'has-error' : _vm.$v.passwords.$error ||
                        _vm.$v.localUser.password.$error},attrs:{"type":"password","placeholder":"Enter new password"},on:{"input":function($event){_vm.$v.passwords.$touch(),
                            _vm.$v.localUser.password.$touch()},"blur":_vm.checkForSame},model:{value:(_vm.localUser.password),callback:function ($$v) {_vm.$set(_vm.localUser, "password", $$v)},expression:"localUser.password"}}),(_vm.$v.passwords.$error ||
                      _vm.$v.localUser.password.$error)?_c('div',{staticClass:"errors"},[(!_vm.$v.passwords.areSame)?_c('span',{staticClass:"error-message"},[_vm._v(" Passwords are not the same ")]):_vm._e(),(!_vm.$v.localUser.password.required)?_c('span',{staticClass:"error-message"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()],1),_c('el-row',[_c('label',[_vm._v(" Confirm password ")]),_c('br'),_c('el-input',{class:{
                      'has-error' : _vm.$v.passwords.$error ||
                        _vm.$v.confirmPassword.$error
                    },attrs:{"type":"password","placeholder":"Confirm password"},on:{"input":function($event){_vm.$v.passwords.$touch(),
                            _vm.$v.confirmPassword.$touch()},"blur":_vm.checkForSame},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),(_vm.$v.passwords.$error ||
                      _vm.$v.confirmPassword.$error)?_c('div',{staticClass:"errors"},[(!_vm.$v.passwords.areSame)?_c('span',{staticClass:"error-message"},[_vm._v(" Passwords are not the same ")]):_vm._e(),(!_vm.$v.confirmPassword.required)?_c('span',{staticClass:"error-message"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()],1)],2)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }