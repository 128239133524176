import { CorePluginOptions } from '@timeragent/core/lib/core.plugin';
import Vue from 'vue';
import { Registry } from 'vue-oop';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import 'moment-business-days';
import 'moment-duration-literal';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { sync } from 'vuex-router-sync';
import { CorePlugin } from '@timeragent/core';
import 'vue-oop-table';

import 'vue-oop-table/dist/vue-oop-table.css';
import store from '@/store';
import router from '@/router';
import workspace from '@/mixins/workspace';
import './registerServiceWorker';
import App from './App.vue';

sync(store, router);

Vue.component('TIcon', FontAwesomeIcon);
Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(ElementUI, { locale });

Vue.use<CorePluginOptions>(CorePlugin, {
  schemaUrl: process.env.VUE_APP_GRAPHQL_HTTP,
  store,
});

Vue.config.productionTip = false;

Vue.mixin(workspace);

const vm = new Vue({
  store,
  // @ts-ignore
  router,
  beforeCreate() {
    Registry.getInstance().set('Vue', this);
  },
  render: h => h(App as any),
}).$mount('#app');

export default vm;
