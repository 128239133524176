import {
  SHOW_ACTIVE_TASK,
  CALCULATE_SCORE,
  RESET_CARDS,
} from './mutation-types';

export default {
  [SHOW_ACTIVE_TASK](state: any, activeTask: object) {
    Object.assign(state, {
      activeTask,
      pointCards: [],
      openedCards: false,
    });
  },
  [CALCULATE_SCORE](state: any, total: number) {
    const { openedCards, activeTask } = state;

    state.tasks = state.tasks.map((el: { id: string; score: number; }) => {
      if (el.id === activeTask.id) {
        el.score = total;
      }

      return el;
    });

    Object.assign(state, {
      openedCards: !openedCards,
    });
  },
  [RESET_CARDS](state: any) {
    Object.assign(state, {
      pointCards: [],
      openedCards: false,
    });
  },
};
