const notification = {
  methods: {
    showSuccess(messageText = 'Operation successful') {
      // @ts-ignore
      this.$notify({
        title: 'Success',
        message: messageText,
        type: 'success',
      });
    },
    showError(messageText = 'Oops, something went wrong...') {
      // @ts-ignore
      this.$notify({
        title: 'Error',
        message: messageText,
        type: 'error',
      });
    },
    showWarning(messageText = 'Warning') {
      // @ts-ignore
      this.$notify({
        title: 'Warning',
        dangerouslyUseHTMLString: true,
        message: messageText,
        type: 'warning',
      });
    },
  },
};

export default notification;
