import { render, staticRenderFns } from "./EditProjectPage.vue?vue&type=template&id=2c9d08d6&scoped=true&"
import script from "./EditProjectPage.vue?vue&type=script&lang=js&"
export * from "./EditProjectPage.vue?vue&type=script&lang=js&"
import style0 from "./EditProjectPage.vue?vue&type=style&index=0&id=2c9d08d6&lang=stylus&rel=stylesheet%2Fcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9d08d6",
  null
  
)

export default component.exports