<template>
  <div class="ProjectsPage projects-page">
    <el-main v-loading="loading">
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <span class="page-title">
            Projects
          </span>
          <el-card>
            <div
              slot="header"
              class="clearfix"
            >
              <a
                v-if="organization.userStatus === 'OWNER'"
                class="el-button el-button--primary is-plain"
                @click="goToNewProject"
              >
                <i class="el-icon-plus" />
                New Project
              </a>
            </div>
            <el-table
              :data="projects.all()"
              :default-sort="{ prop: 'name' }"
              stripe
            >
              <el-table-column
                prop="name"
                label="Name"
                sortable
              />
              <el-table-column
                prop="client.name"
                label="Client"
              />
              <el-table-column
                label="Members"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.teams.length && scope.row.teams.length < 4">
                    Teams:
                    <template v-for="(team, index) in scope.row.teams">
                      <!--TODO change :key-->
                      <el-button
                        :key="`te-${index}`"
                        type="text"
                        @click="showTeamMembers(team)"
                      >
                        {{ team.name }}
                        <span v-if="index !== scope.row.teams.length - 1">
                          ,
                        </span>
                      </el-button>
                    </template>
                  </template>
                  <template v-if="scope.row.teams.length > 3">
                    <el-button
                      type="text"
                      @click="showTeams(scope.row.teams)"
                    >
                      Teams
                    </el-button>
                  </template>
                  <div v-if="scope.row.users.length === 1">
                    User: {{ scope.row.users[0].name }}
                  </div>
                  <div v-if="scope.row.users.length === 2">
                    Users: {{ scope.row.users[0].name }}
                    and {{ scope.row.users[1].name }}
                  </div>
                  <div v-if="scope.row.users.length === 3">
                    Users: {{ scope.row.users[0].name }},
                    {{ scope.row.users[1].name }} and
                    {{ scope.row.users[2].name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                width="80"
              >
                <template slot-scope="scope">
                  <div v-if="organization.userStatus === 'OWNER'">
                    <el-button
                      type="plain"
                      size="mini"
                      @click="goToEditProject(scope.row.uuid)"
                    >
                      Edit
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <template slot="empty">
                <div
                  v-if="projectRepository.loading"
                  class="well text-center"
                >
                  Loading projects, please wait...
                </div>
                <div
                  v-else
                  class="well text-center"
                >
                  No projects yet.
                </div>
              </template>
            </el-table>
            <el-dialog
              :visible.sync="teamsTableVisible"
              title="Teams"
            >
              <el-table
                :data="teams"
                :default-sort="{prop: 'name'}"
              >
                <el-table-column
                  property="name"
                  label="Name"
                  sortable
                />
                <el-table-column
                  property="ownerName"
                  label="Owner(Team lead)"
                />
                <el-table-column
                  label="Members"
                >
                  <template slot-scope="scope">
                    <div
                      v-for="(user, index) in scope.row.users"
                      :key="`us-${index}`"
                    >
                      {{ user.name }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <span
                slot="footer"
                class="dialog-footer"
              >
                <el-button
                  @click="teamsTableVisible = false"
                >
                  Close
                </el-button>
              </span>
            </el-dialog>
            <el-dialog
              :visible.sync="teamMembersTableVisible"
              title="Team Members"
            >
              <el-table
                :data="teamMembers"
                :default-sort="{prop: 'name'}"
              >
                <el-table-column
                  property="name"
                  label="Name"
                  sortable
                />
              </el-table>
              <span
                slot="footer"
                class="dialog-footer"
              >
                <el-button
                  @click="teamMembersTableVisible = false"
                >
                  Close
                </el-button>
              </span>
            </el-dialog>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ProjectRepository } from '@timeragent/core';

import notification from '../../mixins/notification';
import loading from '../../mixins/loading';
import accessRights from '../../mixins/accessRights';

export default {
  mixins: [notification, loading, accessRights],
  data() {
    return {
      teamsTableVisible: false,
      teamMembersTableVisible: false,
      teamMembers: [],
      projectRepository: new ProjectRepository(),

      teams: [],
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'organization',
    ]),
    projects() {
      return this.projectRepository.dataset;
    },
  },

  created() {
    this.projectRepository.many({
      withTasks: false,
      filters: {
        organizationUuid: this.organizationUuid,
      },
    });
  },

  methods: {
    goToNewProject() {
      this.$router.push({
        name: 'newProject',
        params: {
          organizationUuid: this.organizationUuid,
        },
      });
    },
    goToEditProject(projectUuid) {
      this.startLoading();
      this.$router.push({
        name: 'editProject',
        params: {
          organizationUuid: this.organizationUuid,
          projectUuid,
        },
      });
    },
    showTeams(teams) {
      this.teams = teams;
      this.teamsTableVisible = true;
    },
    showTeamMembers(team) {
      this.teamMembersTableVisible = true;
      this.teamMembers = team.users;
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

  a.el-button {
    text-decoration: none;
  }

  a.el-button:hover {
    text-decoration: none;
  }
  a.el-button:focus {
    text-decoration: none;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

</style>
