import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/projects`,
  name: 'projects',
  component: () => import('../components/pages/ProjectsPage.vue'),
  meta: {
    name: 'Projects',
  },
};
