import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/projects/:projectUuid(${uuidRegex})`,
  name: 'editProject',
  component: () => import('../components/pages/EditProjectPage.vue'),
  props: true,
  meta: {
    name: 'Edit Project',
  },
};
