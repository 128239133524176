<template>
  <el-main>
    <el-row>
      <el-col
        :span="12"
        :offset="6"
      >
        <span
          v-if="!confirmMessage"
          class="page-title"
        >
          Set a new password
        </span>
        <span
          v-else
          class="page-title"
        >
          Password updated!
        </span>
        <el-col
          :span="24"
        >
          <el-card>
            <el-row>
              <el-col
                v-if="!confirmMessage"
                :span="22"
                :offset="1"
              >
                <el-form
                  @keyup.enter.native="changePassword"
                >
                  <el-row>
                    <label>New password</label>
                    <br>
                    <div class="password">
                      <el-input
                        v-model="user.password"
                        :class="{
                          'has-error': $v.user.password.$error
                        }"
                        :type="passwordInputType"
                        placeholder="Enter new password"
                        @blur="$v.user.password.$touch()"
                      />
                      <i
                        class="far eye-password"
                        :class="{
                          'fa-eye': passwordToggle,
                          'fa-eye-slash': !passwordToggle,
                        }"
                        @click.prevent="passwordToggle = !passwordToggle"
                      />
                      <div
                        v-if="$v.user.password.$error"
                        class="errors"
                      >
                        <span
                          v-if="!$v.user.password.minLength"
                          class="error-message"
                        >
                          Minimum length 6 characters
                        </span>
                        <span
                          v-if="!$v.user.password.required"
                          class="error-message"
                        >
                          Field is required
                        </span>
                      </div>
                    </div>
                  </el-row>
                  <!--                  Confirm password-->
                  <el-row>
                    <label>
                      Confirm password
                    </label>
                    <br>
                    <div class="password">
                      <el-input
                        v-model="user.confirmPassword"
                        :class="{
                          'has-error': $v.user.confirmPassword.$error
                        }"
                        :type="passwordInputType"
                        placeholder="Confirm password"
                        @blur="$v.user.confirmPassword.$touch()"
                      />
                      <i
                        class="far eye-password"
                        :class="{
                          'fa-eye': passwordToggle,
                          'fa-eye-slash': !passwordToggle,
                        }"
                        @click.prevent="passwordToggle = !passwordToggle"
                      />
                      <div
                        v-if="$v.user.confirmPassword.$error"
                        class="errors"
                      >
                        <span
                          v-if="!$v.user.confirmPassword.required"
                          class="error-message"
                        >
                          Field is required
                        </span>
                        <span
                          v-if="!$v.user.confirmPassword.sameAs"
                          class="error-message"
                        >
                          Passwords are not the same
                        </span>
                      </div>
                    </div>
                  </el-row>
                  <div class="action-buttons">
                    <el-button
                      :disabled="formInvalid"
                      type="primary"
                      @click="changePassword"
                    >
                      Change
                    </el-button>
                  </div>
                </el-form>
              </el-col>
              <el-col
                v-else
                :span="22"
                :offset="1"
              >
                <el-row>
                  <label>Your password has been changed</label>
                  <br>
                  <div class="action-buttons">
                    <el-button
                      :loading="$v.user.$model.loading"
                      type="primary"
                      @click="myTasks"
                    >
                      My tasks
                    </el-button>
                  </div>
                </el-row>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { loginMixin, User } from '@timeragent/core';
import loading from '../../mixins/loading';
import notification from '../../mixins/notification';

export default {
  mixins: [loginMixin, notification, loading],
  props: {
    verificationToken: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      user: new User(),
      currentPassword: '',
      confirmPassword: '',

      confirmMessage: false,
      passwordToggle: true,
    };
  },
  computed: {
    formInvalid() {
      return this.$v.$invalid;
    },
    passwordInputType() {
      return this.passwordToggle ? 'password' : 'text';
    },
  },
  methods: {
    ...mapActions([
      'getMe',
    ]),
    async changePassword() {
      if (this.formInvalid) {
        this.$v.$touch();

        return;
      }

      this.user.verificationToken = this.verificationToken;

      try {
        const message = await this.user.updatePassword();

        await this.saveTokens(message.accessToken, message.refreshToken);
        this.user = await this.getMe();

        this.confirmMessage = true;
      } catch (e) {
        this.showError(e.message);
        this.errors = e;
      }
    },
    myTasks() {
      this.$router.push({ name: 'tasks', params: { organizationUuid: `${this.user.organizations[0].uuid}` } });
    },
  },
  validations() {
    return {
      user: {
        password: {
          required,
          minLength: minLength(6),
        },
        confirmPassword: {
          required,
          sameAs: sameAs('password'),
        },
      },
    };
  },
};
</script>

<style
    lang="stylus"
    rel="stylesheet/css"
    scoped
>

.el-row {
  margin-top: 20px;
}

.page-title {
  padding: 0;
  font-size: 28px;
  line-height: 33px;
  display: block;
  margin: 0 0 25px;
  height: 40px;
  word-break: break-all;
}

body {
  background-color: #efefef;
}

.action-buttons {
  margin-top: 20px;
}

.password {
  position: relative
}

.eye-password {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #656565;
  cursor : pointer;
}

</style>
