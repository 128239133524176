import { uuidRegex } from '@/helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/poker-sessions/:pokerUuid(${uuidRegex})`,
  name: 'editPokerSession',
  component: () => import('../components/pages/poker-planning-page/PokerSession.vue'),
  props: true,
  meta: {
    name: 'Edit Session',
  },
};
