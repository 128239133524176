export default {
  activeTask: {},
  tasks: [
    // { id: '1', name: 'Create Task list', chosen: false, score: null },
    // { id: '2', name: 'Crate Fake list', chosen: false, score: null },
    // { id: '3', name: 'Make it visible', chosen: false, score: null },
    // { id: '4', name: 'To do task', chosen: false, score: null },
    // { id: '5', name: 'To be happy', chosen: false, score: null },
    // { id: '6', name: 'To get marriage', chosen: false, score: null },
    // { id: '7', name: 'Build the house', chosen: false, score: null },
    // { id: '8', name: 'Buy the car', chosen: false, score: null },
    // { id: '9', name: 'Grow the tree', chosen: false, score: null },
    // { id: '10', name: 'Save the World', chosen: false, score: null },
  ],
  selectedCards: false,
  pointCards: [],
  openedCards: false,
};
