import { importAll, initRouter } from '@timeragent/core';
import VueRouter from 'vue-router';
import store from './store';

const routes = importAll(require.context('./pages', false, /\.(ts|js)$/));

const router = new VueRouter({
  mode: 'history',
  routes,
});

// @ts-ignore
initRouter(router, store);

export default router;
