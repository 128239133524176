import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/poker-sessions`,
  name: 'poker-sessions',
  component: () => import('../components/pages/PokerSessions.vue'),
  meta: {
    name: 'Poker Sessions',
  },
};
