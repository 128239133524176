import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/calendar`,
  name: 'Calendar',
  component: () => import('../components/pages/calendar/CalendarPage.vue'),
  meta: {
    name: 'Calendar',
  },
};
