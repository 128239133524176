import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/:operation(edit)`,
  name: 'editOrganization',
  component: () => import('../components/pages/EditOrganizationPage.vue'),
  props: true,
  meta: {
    name: 'Edit Organization',
  },
};
