export default {
  showActiveTask({ commit } : any, activeTask: object) {
    commit('SHOW_ACTIVE_TASK', activeTask);
  },
  calculateScore({ commit, state } : any) {
    let total = 0;

    state.pointCards.forEach((card: { count: boolean; value: number; }) => {
      if (card.count) {
        total += card.value;
      }
    });
    commit('CALCULATE_SCORE', total);
  },
  resetCards({ commit } : any) {
    commit('RESET_CARDS');
  },
};
