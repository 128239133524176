import { User, Organization } from '@timeragent/core';
import { Route } from 'vue-router/types/router';

const accessRights = {
  computed: {
    statusInOrganization() {
      if (!(this.$route).params.organizationUuid) return null;

      // @ts-ignore
      return this.user.organizations
        .find((org: Organization) => org.uuid === this.$route.params.organizationUuid)?.options?.status;
    },
  } as {
    $route: Route,
    user: User,
    statusInOrganization: () => void,
  },
};

export default accessRights;
