export default {
  path: '/recovery',
  name: 'recovery',
  component: () => import('../components/pages/RecoveryPage.vue'),
  meta: {
    name: 'Recovery',
    guest: true,
  },
  props: true,
};
