<template>
  <div>
    <PageHeader
      v-if="activeEntry"
      :active-entry="activeEntry[0]"
      @save-new-entry="(timeEntryRepository.dataset).all().unshift($event)"
    />

    <div v-if="sortedEntries.length">
      <div
        v-for="dayItem in sortedEntries"
        :key="dayItem.date"
        style="margin-top: 60px"
      >
        <TimeEntriesList :day-item="dayItem" />
      </div>

      <div class="button-reports">
        <el-button
          @click="redirectionToReports"
        >
          View full history in Reports
        </el-button>
      </div>
    </div>

    <div
      v-else
      class="default-title"
    >
      <p>Let`s get tracking!</p>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TrackerPage',
  components: {
    PageHeader: () => import('./PageHeader.vue'),
    TimeEntriesList: () => import('./TimeEntriesList.vue'),
  },
  data() {
    return {
      time: '',
      activeEntry: null,
    };
  },
  computed: {
    ...mapGetters([
      'timeEntryRepository',
    ]),
    sortedEntries() {
      return this.groupByEntries((this.timeEntryRepository.dataset).all().filter(t => t.active === false));
    },
  },
  async created() {
    await this.getTimeEntries({
      filters: {
        organizationUuid: this.organizationUuid,
      },
    });

    this.activeEntry = (this.timeEntryRepository.dataset).all().filter(t => t.active === true);
  },
  methods: {
    ...mapActions([
      'getTimeEntries',
    ]),
    groupByEntries(array) {
      const sorted = array.reduce((result, currentValue) => {
        const date = DateTime.fromISO(currentValue.startTime).toISODate();

        (result[date] = result[date] || []).push(currentValue);

        return result;
      }, []);

      return Object.entries(sorted)
        .map(key => ({ date: key[0], entries: key[1] }))
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    redirectionToReports() {
      this.$router.push({
        name: 'reports',
        params: {
          organizationUuid: this.organizationUuid,
        },
      });
    },
  },
};
</script>

<style lang="stylus"
       rel="stylesheet/css"
       scoped
>
.button-reports {
  display: flex;
  justify-content: center;
  padding: 80px 0;
  width: 100%;
}

.default-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: 30px;
}
</style>
