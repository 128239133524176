import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/tracker`,
  name: 'tasks',
  component: () => import('../components/pages/tracker/TrackerPage.vue'),

  meta: {
    name: 'Tracker',
  },
};
