import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/kanban`,
  name: 'kanban',
  component: () => import('../components/pages/kanban/KanbanPage.vue'),
  meta: {
    name: 'Kanban',
  },
};
