import { Task, TimeEntry } from '@timeragent/core';
import moment from 'moment';

export function sortTasks(tasks: Task[]) {
  return tasks.sort((a, b) => {
    const aValue = a.timeEntries?.reduce((acc: any, cur: TimeEntry) => {
      if (cur.active && !acc.active) {
        return cur;
      }
      if (!cur.active && acc.active) {
        return acc;
      }
      const diff = moment(cur.endTime).diff(acc.endTime) >= 0;

      return diff ? acc : cur;
    }, { endTime: a.createdAt });

    const bValue = b.timeEntries?.reduce((acc: any, cur: TimeEntry) => {
      if (cur.active && !acc.active) {
        return cur;
      }
      if (!cur.active && acc.active) {
        return acc;
      }
      const diff = moment(cur.endTime).diff(acc.endTime) >= 0;

      return diff ? acc : cur;
    }, { endTime: b.createdAt });

    if (aValue.active && !bValue.active) {
      return -1;
    }
    if (!aValue.active && bValue.active) {
      return +1;
    }

    return moment(bValue.endTime).diff(aValue.endTime);
  });
}

function formatAmount(amount: number, decimalDigits: number) {
  return Math.floor(
    (amount + 0.00001) * (10 ** decimalDigits),
  ) / (10 ** decimalDigits);
}

function cloneObject<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function stub() {
  // This file will be entirely removed
}

export {
  formatAmount,
  cloneObject,
};
