import Vue from 'vue';
import Vuex from 'vuex';
import state, { IUIState } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import pokerPlanningModule from './modules/poker-planning/poker-planning';
import KanbanModule from './modules/kanban/kanban';

Vue.use(Vuex);

export default new Vuex.Store<IUIState>({
  modules: {
    pokerPlanning: pokerPlanningModule,
    kanban: KanbanModule,
  },
  state,
  actions,
  getters,
  mutations,
});
