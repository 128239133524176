import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/members/invite`,
  name: 'inviteMembers',
  component: () => import('../components/pages/InviteMembersPage.vue'),
  meta: {
    name: 'Manage Members',
  },
};
