import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/members`,
  name: 'members',
  component: () => import('../components/pages/MembersPage.vue'),
  meta: {
    name: 'Members',
  },
};
