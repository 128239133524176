import { Login } from '@timeragent/core';
import { NavigationGuardNext, Route } from 'vue-router';
import { showSuccess } from '@/notification';

export default {
  path: '/verify/:verificationCode',
  name: 'verify',
  component: () => import('../components/pages/VerifyUserPage.vue'),
  props: true,
  meta: {
    name: 'Verify',
    guest: true,
  },

  async beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
    if (localStorage.getItem('accessToken')) {
      next({
        name: 'tasks',
        params: {
          segment: 'personal',
        },
      });
    }
    const login = new Login({
      token: to.params.verificationCode,
    });

    const message = await login.verifyEmail();

    showSuccess(message);

    next({
      name: 'login',
      params: {
        email: to.query.email as string,
      },
    });
  },
};
