var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-row',[_c('el-col',{attrs:{"span":12,"offset":6}},[(!_vm.confirmMessage)?_c('span',{staticClass:"page-title"},[_vm._v(" Set a new password ")]):_c('span',{staticClass:"page-title"},[_vm._v(" Password updated! ")]),_c('el-col',{attrs:{"span":24}},[_c('el-card',[_c('el-row',[(!_vm.confirmMessage)?_c('el-col',{attrs:{"span":22,"offset":1}},[_c('el-form',{nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.changePassword.apply(null, arguments)}}},[_c('el-row',[_c('label',[_vm._v("New password")]),_c('br'),_c('div',{staticClass:"password"},[_c('el-input',{class:{
                        'has-error': _vm.$v.user.password.$error
                      },attrs:{"type":_vm.passwordInputType,"placeholder":"Enter new password"},on:{"blur":function($event){return _vm.$v.user.password.$touch()}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('i',{staticClass:"far eye-password",class:{
                        'fa-eye': _vm.passwordToggle,
                        'fa-eye-slash': !_vm.passwordToggle,
                      },on:{"click":function($event){$event.preventDefault();_vm.passwordToggle = !_vm.passwordToggle}}}),(_vm.$v.user.password.$error)?_c('div',{staticClass:"errors"},[(!_vm.$v.user.password.minLength)?_c('span',{staticClass:"error-message"},[_vm._v(" Minimum length 6 characters ")]):_vm._e(),(!_vm.$v.user.password.required)?_c('span',{staticClass:"error-message"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()],1)]),_c('el-row',[_c('label',[_vm._v(" Confirm password ")]),_c('br'),_c('div',{staticClass:"password"},[_c('el-input',{class:{
                        'has-error': _vm.$v.user.confirmPassword.$error
                      },attrs:{"type":_vm.passwordInputType,"placeholder":"Confirm password"},on:{"blur":function($event){return _vm.$v.user.confirmPassword.$touch()}},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}}),_c('i',{staticClass:"far eye-password",class:{
                        'fa-eye': _vm.passwordToggle,
                        'fa-eye-slash': !_vm.passwordToggle,
                      },on:{"click":function($event){$event.preventDefault();_vm.passwordToggle = !_vm.passwordToggle}}}),(_vm.$v.user.confirmPassword.$error)?_c('div',{staticClass:"errors"},[(!_vm.$v.user.confirmPassword.required)?_c('span',{staticClass:"error-message"},[_vm._v(" Field is required ")]):_vm._e(),(!_vm.$v.user.confirmPassword.sameAs)?_c('span',{staticClass:"error-message"},[_vm._v(" Passwords are not the same ")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"action-buttons"},[_c('el-button',{attrs:{"disabled":_vm.formInvalid,"type":"primary"},on:{"click":_vm.changePassword}},[_vm._v(" Change ")])],1)],1)],1):_c('el-col',{attrs:{"span":22,"offset":1}},[_c('el-row',[_c('label',[_vm._v("Your password has been changed")]),_c('br'),_c('div',{staticClass:"action-buttons"},[_c('el-button',{attrs:{"loading":_vm.$v.user.$model.loading,"type":"primary"},on:{"click":_vm.myTasks}},[_vm._v(" My tasks ")])],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }