export default {
  organization: [
    {
      projectId: 1,
      projectTitle: 'First project',
      kanbanLists: [
        {
          kanbanId: 2,
          kanbanTitle: 'Kanban title',
          columns: [
            {
              id: 1,
              title: 'Backlog',
              tasks: [
                {
                  id: 10,
                  title: 'Task title',
                  completed: false,
                  description: '',
                  members: [
                    {
                      id: 4,
                      name: 'Maxym',
                      src: '',
                    },
                  ],
                  tags: [{ id: 1, title: 'Tag 1' }],
                  priority: 1,
                  dateTime: '',
                  checklist: {
                    title: 'Checklist',
                    subtasks: [
                      {
                        id: 1,
                        todo: true,
                        title: 'Task #1',
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
