<template>
  <div class="kanban-page">
    <el-row>
      <el-col>
        <el-card class="box-card">
          <el-select
            v-model="activeProject"
            placeholder="Select project"
            size="small"
            value-key="uuid"
            @change="changeProject"
          >
            <el-option
              v-for="item in projects"
              :key="item.uuid"
              :label="item.name"
              :value="item"
            >
              {{ item.name }}
            </el-option>
          </el-select>

          <el-select
            ref="selectKanban"
            v-model="activeKanban"
            placeholder="Select board"
            size="small"
            value-key="uuid"
            visible-change="false"
            :automatic-dropdown="true"
            @change="selectedBoard"
          >
            <el-option
              v-for="item in kanbans"
              :key="item.uuid"
              :label="item.title"
              :value="item"
            >
              {{ item.title }}
            </el-option>
          </el-select>

          <!--          "New board" button-->
          <el-popover
            v-model="visible"
            placement="bottom"
            trigger="click"
            width="180"
          >
            <el-input
              v-model="newBoardTitle"
              size="small"
              placeholder="Board title"
            />
            <el-select
              v-model="selectedProject"
              placeholder="Select project"
              size="small"
              value-key="uuid"
            >
              <el-option
                v-for="item in projects"
                :key="item.uuid"
                :label="item.name"
                :value="item"
              />
            </el-select>

            <!--        Dropdown input-->
            <div style="text-align: center; margin-top: 10px">
              <el-button
                type="primary"
                size="small"
                @click="saveNewBoard"
              >
                Save
              </el-button>
              <el-button
                size="small"
                type="text"
                @click="visible = false"
              >
                Cancel
              </el-button>
            </div>
            <el-button
              slot="reference"
              size="medium"
            >
              New board
            </el-button>
          </el-popover>

          <el-button
            size="medium"
            style="margin-left: 20px"
            @click="showArchive = !showArchive"
          >
            <p v-if="!showArchive">
              Show archive
            </p>
            <p v-else>
              Hide archive
            </p>
          </el-button>
        </el-card>
      </el-col>
    </el-row>

    <div
      v-if="activeKanban"
      class="kanban-container"
    >
      <kanban-board
        :kanban="activeKanban"
        :show-archive="showArchive"
        :opened-task="openedTask"
        :fetch-columns-action="fetchColumns"
      />
    </div>
    <el-col
      v-else
      class="message-no-project"
    >
      ☝ Please select your project and board
    </el-col>
  </div>
</template>

<script>
import { Kanban, KanbanColumn, ProjectRepository } from '@timeragent/core';

export default {
  name: 'KanbanPage',
  components: {
    KanbanBoard: () => import('./KanbanBoard.vue'),
  },
  data() {
    return {
      activeProject: null,
      activeKanban: null,
      openedTask: null,
      visible: false,
      fetchColumns: false,
      kanbans: null,
      selectedProject: null,
      newBoardTitle: null,
      showArchive: false,
      projectRepository: new ProjectRepository(),
    };
  },
  computed: {
    projects() {
      return (this.projectRepository.dataset).all();
    },
  },
  watch: {
    activeProject() {
      this.kanbans = this.activeProject?.kanbans || [];
    },
  },
  async created() {
    await this.projectRepository.many({
      filters: {
        ...this.defaultFilters,
        organizationUuid: this.organizationUuid,
      },
      withTasks: false,
    });

    // Get project/kanban uuids from $router
    const { project, kanban } = this.$route.query;

    if (kanban) {
      this.findProjectAndKanban(project, kanban);
    }

    if (!kanban && localStorage.kanbanUuid) {
      const projectUuid = localStorage.getItem('projectUuid');
      const kanbanUuid = localStorage.getItem('kanbanUuid');

      this.routerPush(projectUuid, kanbanUuid);
      this.findProjectAndKanban(projectUuid, kanbanUuid);
    }
  },
  methods: {
    findProjectAndKanban(pj, kan) {
      this.activeProject = this.projects.find(p => p.uuid === pj);
      this.activeKanban = this.activeProject.kanbans.items.find(k => k.uuid === kan);
    },
    changeProject(select = true) {
      this.showArchive = false;
      this.activeKanban = null;
      this.routerPush(this.activeProject.uuid);
      localStorage.setItem('kanbanUuid', null);
      localStorage.setItem('projectUuid', this.activeProject.uuid);

      if (select) {
        this.$nextTick(() => this.$refs.selectKanban.focus());
      }
    },
    selectedBoard(select = true) {
      if (select) {
        this.fetchColumns = false;
        this.$refs.selectKanban.blur();
        this.fetchColumns = true;
      }

      this.showArchive = false;
      this.routerPush(this.activeProject.uuid, this.activeKanban.uuid);
      localStorage.setItem('kanbanUuid', this.activeKanban.uuid);
    },
    async saveNewBoard() {
      this.visible = false;
      this.showArchive = false;

      const kanban = await new Kanban({
        title: this.newBoardTitle,
        projectUuid: this.selectedProject.uuid,
      }).save();

      await new KanbanColumn({
        title: 'Archive',
        kanbanUuid: kanban.uuid,
        isArchive: true,
      }).save();

      this.newBoardTitle = null;
      // Dynamically changing boards
      this.selectedProject.kanbans.push(kanban);
      this.activeProject = this.selectedProject;
      this.activeKanban = kanban;
      this.selectedBoard(false);
    },
    routerPush(projectUuid, kanbanUuid) {
      this.$router.push({
        name: 'kanban',
        query: { project: projectUuid,
          kanban: kanbanUuid },
      });
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>
.el-row,
.message-no-project {
  padding: 23px;
  padding-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.el-select {
  margin-right: 20px;
}
.el-popover .el-select {
  margin: 10px 0 0 0;
}
.el-button {
  margin-left  20pxpx
}
.kanban-container {
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
}
.message-no-project {
  font-size: 22px;
}
</style>
