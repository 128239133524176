<template>
  <div class="EditPokerSession">
    <el-main>
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <div class="title-options">
            <div class="title">
              {{ pokerSession.name }}
            </div>
            <div class="options">
              <el-button
                v-if="organizer"
                class="options-button el-button el-button--primary is-plain"
                @click="show.options = true"
              >
                <!--<i class="el-icon-turn-off" />-->
                Options
              </el-button>
              <el-dialog
                :visible.sync="show.options"
                width="60%"
                :before-close="closeOptions"
              >
                <OptionsPage
                  :poker-session="pokerSession"
                  @close-options="closeOptions"
                />
              </el-dialog>
            </div>
          </div>
          <!--here was el-card tag-->
          <div class="report-container">
            <div class="members-column">
              <MembersColumn
                :poker-session="pokerSession"
                @open-search="openUserSearch"
                @remove-user="deleteMember"
              />
              <el-dialog
                title="Add a User"
                :visible.sync="show.search"
                width="60%"
                :before-close="closeUserSearch"
              >
                <UserSearchWindow
                  :poker-session="pokerSession"
                  @close="closeUserSearch"
                  @invite-users="inviteUsers"
                />
              </el-dialog>
            </div>
            <div class="main-planning-column">
              <div class="display-row">
                <DisplayRow
                  :poker-session="pokerSession"
                  @reset-votes="resetVotes"
                />
              </div>
              <div class="planning-table-row">
                <CardTable
                  :poker-session="pokerSession"
                  :opened-cards="openedCards"
                />
              </div>
              <div class="voting-cards-row">
                <VotingCards
                  :poker-session="pokerSession"
                  @update-task-score="updateTaskScore"
                />
              </div>
            </div>
            <div class="tasks-list-column">
              <StoriesTable
                :poker-session="pokerSession"
                @save-session="save"
                @delete-session="deleteSession"
                @save-tasks="saveTasks"
                @reset-votes="resetVotes"
              />
            </div>
          </div>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import { PokerSession, PokerSessionRepository, Task, UserRepository } from '@timeragent/core';
import { mapGetters } from 'vuex';
import notification from '../../../mixins/notification';

export default {
  name: 'PokerSession',

  components: {
    DisplayRow: () => import('./DisplayRow.vue'),
    VotingCards: () => import('./VotingCards.vue'),
    StoriesTable: () => import('./StoriesTable.vue'),
    MembersColumn: () => import('./MembersColumn.vue'),
    CardTable: () => import('./CardTable.vue'),
    OptionsPage: () => import('./OptionsPage.vue'),
    UserSearchWindow: () => import('./UserSearchWindow.vue'),
  },
  mixins: [notification],

  data() {
    return {
      pokerSession: new PokerSession(),
      pokerSessionRepository: new PokerSessionRepository(),
      userRepository: new UserRepository(),
      show: {
        options: false,
        search: false,
      },
    };
  },

  computed: {
    ...mapGetters([
      'user',
    ]),
    users() {
      return this.userRepository.dataset;
    },
    organizer() {
      if (this.pokerSession.exists()) {
        return this.pokerSession.user.uuid === this.user.uuid;
      }

      return true;
    },
    openedCards() {
      return (this.pokerSession.exists() && this.pokerSession.activeTask && this.pokerSession.activeTask.votes)
        ? this.pokerSession.activeTask.votes.length === this.allowedVotingUsers : false;
    },
    allowedVotingUsers() {
      const array = this.pokerSession.users.filter(el => (el.options.pokerSessionStatus === 'JOINED' && el.options.sessionRole === 'PLAYER'));

      return array.length;
    },
  },

  async created() {
    if (this.$route.params.pokerUuid) {
      this.pokerSession = await this.pokerSessionRepository.one(this.$route.params.pokerUuid);
    }
    await this.userRepository.many({
      organizationUuid: this.$route.params.organizationUuid,
    });
    if (!this.pokerSession.exists()) {
      this.pokerSession.users.push(this.user);
    }
  },

  methods: {
    async save() {
      this.$router.go(-1);
      this.showSuccess(this.pokerSession.exists() ? 'Poker session saved successfully' : 'Poker session created successfully');
      await this.pokerSession.save();
    },
    async deleteSession() {
      await this.pokerSession.delete();
      this.showSuccess('Poker session removed successfully');
      this.$router.go(-1);
    },
    closeOptions() {
      this.show.options = !this.show.options;
    },
    openUserSearch() {
      this.show.search = true;
    },
    closeUserSearch() {
      this.show.search = false;
    },
    inviteUsers(invitedUsers) {
      if (this.pokerSession.exists()) {
        this.pokerSession.inviteUsers(invitedUsers);
      } else {
        this.pokerSession.users = this.pokerSession.users.concat(invitedUsers);
      }
      this.closeUserSearch();
    },
    deleteMember(member) {
      this.pokerSession.deleteMember(member);
    },
    saveTasks() {
      if (this.pokerSession.exists()) {
        this.pokerSession.save();
      }
    },
    updateTaskScore() {
      if (this.allowedVotingUsers === this.pokerSession.activeTask.votes.length) {
        const initialValue = 0;

        this.pokerSession.activeTask.score = this.pokerSession.activeTask.votes.reduce((accumulator, currentValue) => accumulator + currentValue.score,
          initialValue);
        const task = new Task(this.pokerSession.activeTask);

        task.update();
      }
    },
    resetVotes() {
      if (this.organizer && this.pokerSession.activeTask) {
        this.pokerSession.activeTask.votes = null;
        const task = new Task(this.pokerSession.activeTask);

        task.update();
      }
    },
  },
};
</script>

<style scoped>
    .title-options {
      display: grid;
      grid-template-columns: 77% 23%;
    }
    .title {
      display: grid;
      padding: 0;
      font-size: 28px;
      line-height: 33px;
      /*display: block;*/
      margin: 0 0 25px;
      height: 40px;
      word-break: break-all;
    }
    .options {
      display: grid;
      margin-bottom: 25px;
    }
    .options-button {
      /*max-width: 120px;*/
    }
    .report-container {
        display: grid;
        grid-template-columns: 5% 70% 23%;
        grid-column-gap: 1%;
        height: 600px;
    }
    .members-column {
        display: grid;
        justify-items: stretch;
    }
    .main-planning-column {
        display: grid;
        justify-items: stretch;
        grid-template-rows: 25% 50% 25%;
    }
    .tasks-list-column {
        display: grid;
        justify-items: stretch;
    }
    .display-row {
        display: grid;
        justify-items: stretch;
    }
    .planning-table-row {
        display: grid;
        justify-items: stretch;
    }
    .voting-cards-row {
        display: grid;
        justify-items: stretch;
    }
</style>
