import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/tasks`,
  name: 'tasks',
  component: () => import('../components/pages/TasksPage.vue'),

  meta: {
    name: 'Tasks',
  },
};
