import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/view`,
  name: 'organization',
  component: () => import('../components/pages/OrganizationPage.vue'),
  meta: {
    name: 'Organization',
  },
};
