import { mapActions, mapGetters } from 'vuex';

const loading = {
  computed: {
    ...mapGetters([
      'loading',
    ]),
  },
  created() {
    // @ts-ignore
    this.stopLoading();
  },
  methods: {
    ...mapActions([
      'stopLoading',
    ]),
    startLoading(path: string) {
      // @ts-ignore
      if (path !== this.$route.path) {
        // @ts-ignore
        this.$store.dispatch('startLoading');
      }
    },
    cancel() {
      this.startLoading('');
      // @ts-ignore
      this.$router.go(-1);
    },
  },
};

export default loading;
