<template>
  <div class="PlanningSessionsPage">
    <el-main v-loading="loading">
      <el-row>
        <el-col
          :span="22"
          :offset="1"
        >
          <span class="page-title">
            Poker sessions
          </span>
          <el-card>
            <div
              slot="header"
              class="clearfix"
            >
              <router-link
                :to="{
                  name: 'newPokerSession',
                  params: {
                    segment: 'organization',
                    organizationUuid: $route.params.organizationUuid
                  }
                }"
                class="el-button el-button--primary is-plain"
              >
                <i class="el-icon-plus" />
                New Session
              </router-link>
            </div>
            <el-table
              :data="sessions.all()"
              :default-sort="{prop: 'name'}"
              stripe
            >
              <el-table-column
                prop="name"
                label="Name"
                sortable
              />
              <el-table-column
                prop="description"
                label="Description"
              />
              <el-table-column
                label=""
                width="80"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      v-if="scope.row.users.some(el => (el.uuid === user.uuid) && el.options.pokerSessionStatus === 'PENDING')"
                      type="success"
                      size="mini"
                      @click="acceptInvitation(scope.row)"
                    >
                      Accept
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label=""
                width="85"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      v-if="scope.row.users.some(el => (el.uuid === user.uuid) && el.options.pokerSessionStatus === 'PENDING')"
                      type="danger"
                      size="mini"
                      @click="declineInvitation(scope.row)"
                    >
                      Decline
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label=""
                width="80"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      v-if="scope.row.users.some(el => el.options.pokerSessionStatus === 'JOINED') || scope.row.user.uuid === user.uuid"
                      type="plain"
                      size="mini"
                      @click="goToSession(scope.row.uuid)"
                    >
                      Play
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <template slot="empty">
                <div
                  v-if="pokerSessionRepository.loading"
                  class="well text-center"
                >
                  Loading sessions, please wait...
                </div>
                <div
                  v-else
                  class="well text-center"
                >
                  No sessions yet.
                </div>
              </template>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PokerSession, PokerSessionRepository } from '@timeragent/core';
import notification from '../../mixins/notification';
import loading from '../../mixins/loading';

export default {
  name: 'PokerSessions',

  mixins: [notification, loading],

  data: () => ({
    pokerSession: new PokerSession(),
    pokerSessionRepository: new PokerSessionRepository(),
  }),

  computed: {
    ...mapGetters([
      'organization',
      'user',
    ]),
    sessions() {
      return this.pokerSessionRepository.dataset;
    },
  },

  created() {
    this.pokerSessionRepository.many({
      filters: {
        organizationUuid: this.$route.params.organizationUuid,
      },
    });
    if (this.$route.query.action === 'accept') {
      this.pokerSession.acceptInvite(this.$route.query.uuid);
      this.showSuccess('Poker session invitation accepted successfully');
      this.$router.replace({ name: 'poker-sessions', params: { organizationUuid: this.$route.params.organizationUuid } });
    }
    if (this.$route.query.action === 'decline') {
      this.pokerSession.declineInvite(this.$route.query.uuid);
      this.showSuccess('Poker session invitation declined successfully');
      this.$router.replace({ name: 'poker-sessions', params: { organizationUuid: this.$route.params.organizationUuid } });
    }
  },

  methods: {
    goToSession(pokerUuid) {
      this.$router.push({ name: 'editPokerSession', params: { pokerUuid } });
    },
    generateUrl(url) {
      return `/${this.$route.params.segment}/${this.$route.params.organizationUuid}/${url}`;
    },
    acceptInvitation(session) {
      const user = session.users.find(el => (el.uuid === this.user.uuid));

      this.pokerSession.acceptInvite(user.options.inviteUuid);
      this.showSuccess('Poker session invitation accepted successfully');
    },
    declineInvitation(session) {
      const user = session.users.find(el => (el.uuid === this.user.uuid));

      this.pokerSession.declineInvite(user.options.inviteUuid);
      this.showSuccess('Poker session invitation declined successfully');
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

  a.el-button {
    text-decoration: none;
  }

  a.el-button:hover {
    text-decoration: none;
  }

  a.el-button:focus {
    text-decoration: none;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  body {
    background-color: #efefef;
  }

</style>
