import { render, staticRenderFns } from "./ClientsPage.vue?vue&type=template&id=9aaecd48&scoped=true&"
import script from "./ClientsPage.vue?vue&type=script&lang=js&"
export * from "./ClientsPage.vue?vue&type=script&lang=js&"
import style0 from "./ClientsPage.vue?vue&type=style&index=0&id=9aaecd48&lang=stylus&rel=stylesheet%2Fcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9aaecd48",
  null
  
)

export default component.exports