<template>
  <div class="EditClientPage edit-client-page">
    <div class="header">
      <div class="page-title">
        {{ localClient.exists() ? 'Edit Client' : 'New Client' }}
      </div>
      <div>
        <el-button
          :disabled="localClient.loading"
          plain
          @click.prevent="cancel"
        >
          Cancel
        </el-button>
        <el-button
          :disabled="formInvalid || localClient.loading"
          type="success"
          title="Click to save"
          @click.prevent="save"
        >
          <i
            v-if="localClient.loading"
            class="el-icon-loading"
          />
          Save
        </el-button>
      </div>
    </div>
    <el-card class="wrapper">
      <div class="form">
        <div class="form__item">
          <label>Name</label>
          <el-input
            v-model="localClient.name"
            :class="{
              'has-error': $v.localClient.name.$error
            }"
            placeholder="Enter client name"
            @input="$v.localClient.name.$touch()"
          />
          <div
            v-if="$v.localClient.name.$error"
            class="errors"
          >
            <span
              v-if="!$v.localClient.name.required"
              class="error-message"
            >
              Field is required
            </span>
          </div>
        </div>
        <div class="form__item">
          <label>
            Invoice prefix
          </label>
          <el-input
            v-model="localClient.invoicePrefix"
            :class="{
              'has-error': $v.localClient
                .invoicePrefix.$error
            }"
            placeholder="Enter invoice prefix"
            @input="$v.localClient.invoicePrefix.$touch()"
          />
          <div
            v-if="$v.localClient.invoicePrefix.$error"
            class="errors"
          >
            <span
              v-if="!$v.localClient.invoicePrefix.maxLength"
              class="error-message"
            >
              Invoice prefix shouldn't
              have more then 2 letters
            </span>
          </div>
        </div>
        <div class="form__item">
          <div class="billing-frequency">
            <div>
              <div>
                Billing frequency:
              </div>
              <el-select
                v-model="localClient.billingFrequency.frequency"
                size="mini"
              >
                <el-option
                  v-for="option in billingOptions"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                  :disabled="option.disabled"
                />
              </el-select>
            </div>
            <div>
              <div>
                Initial date:
              </div>
              <el-select
                v-model="localClient.billingFrequency.initialDate"
                :disabled="isFrequencyNone"
                size="mini"
              >
                <el-option
                  v-for="option in InitialDateOptions"
                  :key="option"
                  :label="option"
                  :value="option"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="form__item">
          <label>
            Address
          </label>
          <el-input
            v-model="localClient.address"
            :rows="5"
            type="textarea"
            placeholder="Enter client address"
          />
        </div>
        <div class="form__item">
          <label>
            Website
          </label>
          <el-input
            v-model="localClient.website"
            placeholder="Enter website of contact person"
          />
        </div>
        <div class="form__item">
          <label>
            First name
          </label>
          <el-input
            v-model="localClient.contact.firstName"
            :class="{
              'has-error': $v.localClient.contact.firstName.$error
            }"
            placeholder="Enter first name of contact person"
            @input="$v.localClient.contact.firstName.$touch()"
          />
          <div
            v-if="$v.localClient.contact.firstName.$error"
            class="errors"
          >
            <span
              v-if="!$v.localClient.contact.firstName.required"
              class="error-message"
            >
              This field is required
            </span>
          </div>
        </div>
        <div class="form__item">
          <label>
            Last name
          </label>
          <el-input
            v-model="localClient.contact.lastName"
            placeholder="Enter last name of contact person"
          />
        </div>
        <div class="form__item">
          <label>
            Email
          </label>
          <el-input
            v-model="localClient.contact.email"
            :class="{
              'has-error': $v.localClient.contact.email.$error
            }"
            placeholder="Enter email of contact person"
            @input="$v.localClient.contact.email.$touch()"
            @blur="validateEmail"
          />
          <div
            v-if="$v.localClient.contact.email.$error"
            class="errors"
          >
            <span
              v-if="!$v.localClient.contact.email.required"
              class="error-message"
            >
              This field is required
            </span>
            <span
              v-if="!$v.localClient.contact.email.isEmail"
              class="error-message"
            >
              Invalid email
            </span>
          </div>
        </div>
        <div class="form__item">
          <label>
            Telephone
          </label>
          <el-input
            v-model="localClient.contact.telephone"
            placeholder="Enter telephone of contact person"
          />
        </div>
        <div class="form__item">
          <el-button
            v-if="localClient.exists()"
            type="text"
            class="delete_button"
            @click="showConfirmModal = true"
          >
            Delete client
          </el-button>
        </div>

        <!-- Confirm delete client modal form -->
        <el-dialog
          v-if="localClient.exists()"
          :visible.sync="showConfirmModal"
          title="Delete client"
          width="30%"
        >
          <p>
            It will not be undone. Please
            enter client name to continue:
            <br>
            ({{ localClient.name }})
          </p>
          <el-input
            v-model="clientName"
            placeholder="Enter client name"
          />
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              @click.prevent="showConfirmModal = false"
            >
              Cancel
            </el-button>
            <el-button
              :disabled="!confirmDelete"
              type="danger"
              @click.prevent="deleteClient"
            >
              Delete
            </el-button>
          </span>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { ClientRepository, Client } from '@timeragent/core';

import notification from '../../mixins/notification';
import loading from '../../mixins/loading';

const BillingFrequency = {
  NONE: 'NONE',
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  BI_WEEKLY: 'BI_WEEKLY',
};

export default {
  mixins: [notification, loading],

  props: {
    clientUuid: {
      type: String,
      default: () => '',
    },
  },

  data: () => ({
    isEmail: true,
    clientName: '',

    showConfirmModal: false,

    localClient: new Client(),

    clientRepository: new ClientRepository(),
    billingOptions: [
      {
        value: BillingFrequency.NONE,
        label: 'None',
        disabled: false,
      },
      {
        value: BillingFrequency.MONTHLY,
        label: 'Monthly',
        disabled: false,
      },
      {
        value: BillingFrequency.BI_WEEKLY,
        label: 'Bi-weekly',
        disabled: false,
      },
      {
        value: BillingFrequency.WEEKLY,
        label: 'Weekly',
        disabled: false,
      },
    ],
    InitialDateOptions: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
      16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
    ],
  }),

  computed: {
    formInvalid() {
      return this.$v.$invalid;
    },
    confirmDelete() {
      return this.localClient.name === this.clientName;
    },
    frequency() {
      return this.localClient.billingFrequency.frequency;
    },
    isFrequencyNone() {
      return this.localClient.billingFrequency.frequency === BillingFrequency.NONE;
    },
  },
  watch: {
    frequency(value) {
      if (value === BillingFrequency.NONE) {
        this.localClient.billingFrequency.initialDate = null;
      }
    },
  },

  async created() {
    if (this.$route.params.clientUuid) {
      this.localClient = await this.clientRepository.one(this.$route.params.clientUuid);
    }
  },

  methods: {
    async save() {
      if (this.formInvalid) return;
      try {
        await this.localClient.save();

        this.showSuccess(this.localClient.exists() ? 'Client saved successfully' : 'Client created successfully');
        this.$router.go(-1);
      } catch (e) {
        this.showError(e.message);
      }
    },
    async deleteClient() {
      try {
        await this.localClient.delete();

        this.showSuccess('Client removed successfully');
        this.$router.go(-1);
      } catch (e) {
        this.showError(e.message);
      }
    },
    validateEmail() {
      this.isEmail = !!this.localClient.contact.email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i);
    },
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
  },

  validations: {
    localClient: {
      name: {
        required,
      },
      invoicePrefix: {
        maxLength: maxLength(2),
      },
      contact: {
        firstName: {
          required,
        },
        email: {
          required,
          isEmail() {
            return this.isEmail;
          },
        },
      },
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/stylus"
  scoped
>

  .edit-client-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1723px;
  }

  .wrapper {
    max-width: 1723px;
    width: 100%;

    & >>> {
      .el-card__body {
        display: flex;
        justify-content: center;
      }
    }
  }

  .form {
    width: 100%;
    max-width: 1120px;

    &__item:not(:first-child) {
      margin-top: 20px;
    }
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  .billing-frequency {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    text-align: left;
    grid-gap: 10px;
  }

  body {
    background-color: #efefef;
  }

</style>
