<template>
  <div id="app">
    <el-container
      v-if="booted"
      direction="vertical"
    >
      <NavMenuAuth v-if="user.organizations.length" />
      <NavMenu v-else />
      <router-view :key="$route.name" />
    </el-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MODULE_REGISTERED } from './store/mutation-types';

export default {
  name: 'App',

  components: {
    NavMenuAuth: () => import('./components/blocks/NavMenuAuth.vue'),
    NavMenu: () => import('./components/blocks/NavMenu.vue'),
  },

  data: () => ({
    booted: false,
  }),

  computed: {
    ...mapGetters(['user']),
  },

  created() {
    this.$store.subscribe(({ type, payload }) => {
      if (type === MODULE_REGISTERED && payload === 'core') {
        this.booted = true;
      }
    });
  },
};
</script>

<style lang="stylus"
       rel="stylesheet/css">
  /*@import url('https://fonts.googleapis.com/css?family=Advent+Pro|Lato|Montserrat');*/
  @import url('https://fonts.googleapis.com/css?family=Advent+Pro|Lato');
  @import url('https://use.fontawesome.com/releases/v5.3.1/css/all.css');

  body, button, html, input, select, textarea {
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #676f7a;
    margin: 0;
    padding: 0;
  }

  .has-error {
    color: #e02c48 !important;
  }

  .has-error input {
    border-bottom-color: #e02c48 !important;
  }

  .has-error:focus {
    border-bottom: 2px solid #e02c48 !important;
  }

  .error-icon {
    position: relative;
    margin: 5px;
    color: #e02c48;
  }

  .error-icon:before {
    color: #e02c48;
    display: block;
    font-size: 20px;
    margin: 5px;
    position: relative;
  }

  .error-message {
    color: red;
    display: block;
    font-size: 13px;
    margin-top: 2px;
  }

  .error-icon:hover .errors {
    display: block;
  }

  a {
    cursor: pointer;
  }

  p, h1, h2, h3 {
    margin 0;
  }

  .el-table td:first-child .cell, .el-table th:first-child .cell {
    padding-left: 10px;
  }

  .el-collapse-item__header {
    padding: 0 10px;
  }

  .member-item {
    padding: 0;
  }

  .rate {
    float: right;
    margin-right: 10px;
  }

  .el-transfer {
    display: inline-block;
  }

  .delete_button {
    color: #FA5555;
  }

  .delete_button:active {
    color: #FA5555;
  }

  .delete_button:hover {
    color: #FA5555;
  }

  .delete_button:focus {
    color: #FA5555;
  }

  .gray-text {
    color: #b4bccc;
  }

  .green-text {
    color: #67c23a;
  }

  .el-icon-plus {
    font-size: 14px;
  }

  .pull-right {
    float: right;
  }
</style>
