import { render, staticRenderFns } from "./InvoicePage.vue?vue&type=template&id=e5353596&scoped=true&"
import script from "./InvoicePage.vue?vue&type=script&lang=js&"
export * from "./InvoicePage.vue?vue&type=script&lang=js&"
import style0 from "./InvoicePage.vue?vue&type=style&index=0&id=e5353596&scoped=true&lang=stylus&rel=stylesheet%2Fcss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5353596",
  null
  
)

export default component.exports