import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/teams/:teamUuid(${uuidRegex})`,
  name: 'editTeam',
  component: () => import('../components/pages/EditTeamPage.vue'),
  props: true,
  meta: {
    name: 'Edit Team',
  },
};
