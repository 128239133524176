import { uuidRegex } from '../helpers/constants';

export default {
  path: `/:organizationUuid(${uuidRegex})/clients`,
  name: 'clients',
  component: () => import('../components/pages/ClientsPage.vue'),
  meta: {
    name: 'Clients',
  },
};
